
// -----------------------------------
// ||         Chef_equipe         ||
// -----------------------------------

.chef_equipe {
	#section1 {

	}

	#section2 {
		background: url(../images/chef_equipe_henrique.jpg) no-repeat left center;
		background-size: cover;

		.place_dessous {
			height: 20vh;
		}

		.col_gauche {
			img {
				padding: 10vw;
				width: 100%;
				opacity: 0;
			}
		}

		.col_droite {
			.filets {
				flex-basis: 30px;
				flex-grow: 0;
				padding-left: 10px;
				.mot {
				    top: 50px;
				    left: 30px;
				    transform-origin: 0% 50%;
				    transform: rotate(-90deg);
				    color: $color_white;
				}

				.ep1 {
					position: absolute;
					left: 6px;
					bottom: 0;
					height: 70%;
				}
				.ep2 {
					top: -20%;
					height: 120%
				}
			}
		}

		.texte_henrique {
			position: absolute;
			top: 10%;
			padding: 3vw;
			padding-left: 50vw;
			background-color: rgba($color_black, 0.6);
			p, h1 { color: $color_white; }
			h1 {
				font-size: 4rem;
				font-family: "questa-grande";
			}
		}
	}

	#section3 {
		padding: 70px 0;
		.col_gauche {
			padding-right: 50px;
			.titre {
				font-size: 1.2rem;
				font-weight: 700;
				margin-bottom: 0;
			}
			.soustitre {
				font-size: 1.2rem;
				font-weight: 300;
				font-style: italic;
				margin-top: 0;
			}
			.texte {
				margin-bottom: 30px;
			}
		}

		.col_droite {
			padding-left: 50px;
			img {
				width: 72%;
				object-fit: cover;
				aspect-ratio: 1/1;
			}
			.img2, .img3 { margin-top: 30px; }
			//.img3, .img5, .img4 { margin-top: 30px; }
			// .img2, .img4, .img6 { transform: translateY(50%); }
		}
	}

	#section4 {
		height: 60vh;
		margin-bottom: 600px;

		> img { 
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: top;
		}

		.cadre {
			position: absolute;
			z-index: 1000;
			left: 10%;
			top: 50%;
			padding: 40px;
			background-color: rgba($color_black, 0.3);
			h1, p { color: $color_white; }

			h1 {
				font-size: 4rem;
				font-weight: 700;
				font-family: "questa-grande";
			}

			.signature {
				text-align: right;
				font-style: italic;
			}

			.img_container {
				position: relative;
				z-index: 2;

				img {
					z-index: 2;
					width: 50%;
				}

				.img5 {
					position: absolute;
					left: 0;
					bottom: 0;
					transform: translateY(calc(100% + 20px));
				}

				.img6 {
					position: absolute;
					left: 0;
					bottom: 0;
					transform: translateY(calc(200% + 40px));
				}
			}

		.img7 {
			position: absolute;
			z-index: 1;
			width: 100%;
			left: 40%;
			top: 80%;
		}

		}

		.filet {
			position: absolute;
			height: 500px;
			width: 4px;
			background-color: $color_black;
			right: 20%;
			top: 40%;
		}
	}

	#section5 {

	}
}


@include media-breakpoint-down($responsive_breakpoint) {
	.chef_equipe {
		#section1 {
			.absolute_centered {
				width: 90%;
			}
		}

		#section2 {
			.col_gauche { display: none; }

			.texte_henrique {
				position: relative;
				top: 10%px;
				padding: 3vw;
			}
		}

		#section3 {
			.col_gauche {
				padding: 0;
			}

			.col_droite {
				display: none;
			}
		}

		#section4 {
			.filet { display: none }

			.cadre {
				left: 5%;
				width: 90%;

				.img7 {
					left: 0;
				}
			}
		}
	}
}