
// -----------------------------------
// ||           Carousels           ||
// -----------------------------------


// -------------- Options --------------
$carousel_id: 'carousel_accueil';
$carousel_height: 100%;
$carousel_width: 70%;
$carousel_control_width: 10%;
$carousel_control_size: 1rem;
$carousel_control_align: center; // top, bottom, center
$carousel_control_distance: 80px;
$carousel_indicators_bottom_distance: 20px;
$carousel_indicators_height: 3px;
$carousel_indicators_width: 20px;
$carousel_indicators_spacing: 6px;
$carousel_indicators_color: rgba($color_black, 0.4);
$carousel_indicators_border: none;
$carousel_indicators_borderradius: 0;
$carousel_indicators_active_color: rgba($color_black, 1);
$carousel_indicators_active_border: keep; // keep = garder valeur non active
$carousel_indicators_center_size: 0px;
$carousel_indicators_center_color: rgba($color_black, 0);
$carousel_indicators_active_center_size: 0px;
$carousel_indicators_active_center_color: rgba($color_black, 1);
$carousel_indicators_transition: 0.2s;
// -------------------------------------
##{$carousel_id} {
	position: relative;
	height: $carousel_height;
	width: $carousel_width;
	.content_over_carousel {
		position: absolute;
		z-index: 10;
	}
	.carousel-inner {
		position: relative; height: 100%; width: 100%;
		.carousel-item {
			height: 100%; width: 100%;
			img {
				position: absolute;
				z-index: -100;
				top: 0; left: 0;
				width: 100%; height: 100%;
				object-fit: cover;
			}
		}
	}
	.carousel-control {
		width: $carousel_control_width;
		i { font-size: $carousel_control_size !important; }
		@if $carousel_control_align == top { 
			align-items: start;
			padding-top: $carousel_control_distance;
		}
		@if $carousel_control_align == bottom {
			align-items: end;
			padding-bottom: $carousel_control_distance;
		}
	}
	.carousel-control-prev {  }
	.carousel-control-next {  }
	.carousel-indicators {
		@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
		cursor: pointer;
		margin: 0;
		bottom: $carousel_indicators_bottom_distance;
		li {
			@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
			height: $carousel_indicators_height;
			width: $carousel_indicators_width;
			margin: 0 $carousel_indicators_spacing / 2;
			background-color: $carousel_indicators_color;
			border: $carousel_indicators_border;
			border-radius: $carousel_indicators_borderradius;
			.carousel-indicator-inside {
				@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
				@extend %absolutecenter_xy;
				border-radius: $carousel_indicators_borderradius;
				height: $carousel_indicators_center_size;
				width: $carousel_indicators_center_size;
				background-color: $carousel_indicators_center_color;
			}
		}
		li.active {
			background-color: $carousel_indicators_active_color;
			@if $carousel_indicators_active_border == keep {
				border: $carousel_indicators_border;
			} else {
				border: $carousel_indicators_active_border;
			}
			.carousel-indicator-inside {
				@extend %absolutecenter_xy;
				height: $carousel_indicators_active_center_size;
				width: $carousel_indicators_active_center_size;
				background-color: $carousel_indicators_active_center_color;
			}
		}
	}

	.carousel-indicators {
		transform: rotate(90deg);
		right: -80px;
		left: auto;
	}
}



// -------------- Options --------------
$carousel_id: 'carousel_galerie_contact';
$carousel_height: 100%;
$carousel_width: 100%;
$carousel_control_width: 10%;
$carousel_control_size: 1.5rem;
$carousel_control_align: center; // top, bottom, center
$carousel_control_distance: 80px;
$carousel_indicators_bottom_distance: 20px;
$carousel_indicators_height: 3px;
$carousel_indicators_width: 20px;
$carousel_indicators_spacing: 6px;
$carousel_indicators_color: rgba($color_white, 0.4);
$carousel_indicators_border: none;
$carousel_indicators_borderradius: 0;
$carousel_indicators_active_color: rgba($color_white, 1);
$carousel_indicators_active_border: keep; // keep = garder valeur non active
$carousel_indicators_center_size: 0px;
$carousel_indicators_center_color: rgba($color_white, 0);
$carousel_indicators_active_center_size: 0px;
$carousel_indicators_active_center_color: rgba($color_white, 1);
$carousel_indicators_transition: 0.2s;
// -------------------------------------
##{$carousel_id} {
	position: relative;
	height: $carousel_height;
	width: $carousel_width;
	.content_over_carousel {
		position: absolute;
		z-index: 10;
	}
	.carousel-inner {
		position: relative; height: 100%; width: 100%;
		.carousel-item {
			height: 100%; width: 100%;
			img {
				position: absolute;
				z-index: -100;
				top: 0; left: 0;
				width: 100%; height: 100%;
				object-fit: cover;
			}
		}
	}
	.carousel-control {
		width: $carousel_control_width;
		opacity: 0.6;
		i { 
			padding: 6px 10px;
			font-size: $carousel_control_size !important; 
			border-radius: 100px;
			color: $color_black;
			background-color: $color_white;
		}
		@if $carousel_control_align == top { 
			align-items: start;
			padding-top: $carousel_control_distance;
		}
		@if $carousel_control_align == bottom {
			align-items: end;
			padding-bottom: $carousel_control_distance;
		}
	}
	.carousel-control-prev {  }
	.carousel-control-next {  }
	.carousel-indicators {
		@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
		cursor: pointer;
		margin: 0;
		bottom: $carousel_indicators_bottom_distance;
		li {
			@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
			height: $carousel_indicators_height;
			width: $carousel_indicators_width;
			margin: 0 $carousel_indicators_spacing / 2;
			background-color: $carousel_indicators_color;
			border: $carousel_indicators_border;
			border-radius: $carousel_indicators_borderradius;
			.carousel-indicator-inside {
				@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
				@extend %absolutecenter_xy;
				border-radius: $carousel_indicators_borderradius;
				height: $carousel_indicators_center_size;
				width: $carousel_indicators_center_size;
				background-color: $carousel_indicators_center_color;
			}
		}
		li.active {
			background-color: $carousel_indicators_active_color;
			@if $carousel_indicators_active_border == keep {
				border: $carousel_indicators_border;
			} else {
				border: $carousel_indicators_active_border;
			}
			.carousel-indicator-inside {
				@extend %absolutecenter_xy;
				height: $carousel_indicators_active_center_size;
				width: $carousel_indicators_active_center_size;
				background-color: $carousel_indicators_active_center_color;
			}
		}
	}
}



// -------------- Options --------------
$carousel_id: 'carousel_galerie_accueil';
$carousel_height: 100%;
$carousel_width: 100%;
$carousel_control_width: 10%;
$carousel_control_size: 1.5rem;
$carousel_control_align: center; // top, bottom, center
$carousel_control_distance: 80px;
$carousel_indicators_bottom_distance: 20px;
$carousel_indicators_height: 3px;
$carousel_indicators_width: 20px;
$carousel_indicators_spacing: 6px;
$carousel_indicators_color: rgba($color_white, 0.4);
$carousel_indicators_border: none;
$carousel_indicators_borderradius: 0;
$carousel_indicators_active_color: rgba($color_white, 1);
$carousel_indicators_active_border: keep; // keep = garder valeur non active
$carousel_indicators_center_size: 0px;
$carousel_indicators_center_color: rgba($color_white, 0);
$carousel_indicators_active_center_size: 0px;
$carousel_indicators_active_center_color: rgba($color_white, 1);
$carousel_indicators_transition: 0.2s;
// -------------------------------------
##{$carousel_id} {
	position: relative;
	height: $carousel_height;
	width: $carousel_width;
	.content_over_carousel {
		position: absolute;
		z-index: 10;
	}
	.carousel-inner {
		position: relative; height: 100%; width: 100%;
		.carousel-item {
			height: 100%; width: 100%;
			img {
				position: absolute;
				z-index: -100;
				top: 0; left: 0;
				width: 100%; height: 100%;
				object-fit: cover;
			}
		}
	}
	.carousel-control {
		width: $carousel_control_width;
		opacity: 0.6;
		i { 
			padding: 6px 10px;
			font-size: $carousel_control_size !important; 
			border-radius: 100px;
			color: $color_black;
			background-color: $color_white;
		}
		@if $carousel_control_align == top { 
			align-items: start;
			padding-top: $carousel_control_distance;
		}
		@if $carousel_control_align == bottom {
			align-items: end;
			padding-bottom: $carousel_control_distance;
		}
	}
	.carousel-control-prev {  }
	.carousel-control-next {  }
	.carousel-indicators {
		@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
		cursor: pointer;
		margin: 0;
		bottom: $carousel_indicators_bottom_distance;
		li {
			@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
			height: $carousel_indicators_height;
			width: $carousel_indicators_width;
			margin: 0 $carousel_indicators_spacing / 2;
			background-color: $carousel_indicators_color;
			border: $carousel_indicators_border;
			border-radius: $carousel_indicators_borderradius;
			.carousel-indicator-inside {
				@if $carousel_indicators_transition != 0 { transition: $carousel_indicators_transition; }
				@extend %absolutecenter_xy;
				border-radius: $carousel_indicators_borderradius;
				height: $carousel_indicators_center_size;
				width: $carousel_indicators_center_size;
				background-color: $carousel_indicators_center_color;
			}
		}
		li.active {
			background-color: $carousel_indicators_active_color;
			@if $carousel_indicators_active_border == keep {
				border: $carousel_indicators_border;
			} else {
				border: $carousel_indicators_active_border;
			}
			.carousel-indicator-inside {
				@extend %absolutecenter_xy;
				height: $carousel_indicators_active_center_size;
				width: $carousel_indicators_active_center_size;
				background-color: $carousel_indicators_active_center_color;
			}
		}
	}
}



// Carousels modales

.modal-body {
	.carousel {
		position: relative;
		height: 100%;
		width: 100%;

		.carousel-inner {
			position: relative;
			height: 100%;
			width: 100%;

			.carousel-item {
				height: 100%;
				width: 100%;

				img {
					position: absolute;
					z-index: -100;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}

			}


		}

		.carousel-control {

		}
		.carousel-control-prev {

		}
		.carousel-control-next {
			
		}

		.carousel-indicators {
			cursor: pointer;
		}
	}
}