
// -----------------------------------
// ||         Contact acces         ||
// -----------------------------------

.contact_acces {

	.confirmations {
		width: 100%;
		padding: 20px;
		height: auto;
		background-color: $color_white;
		margin-bottom: 20px;
		display: none;
	}

	#section1 {

	}

	#section2 {
		background-color: $color_grisclair;

		.col_gauche {

			.bloc_gris {
				padding: 4vw;
				padding-left: 10vw;
				padding-right: 10vw;
				background-color: $color_grisclair;

				.titre {
					font-weight: 700;
				}
			}

			.signature {
				font-size: 0.85rem;
				text-align: right;
				margin-bottom: 30px;
			}

			#carte_googlemaps {
				height: 300px;
			}
		}

		.col_droite {
			padding: 40px;

			.filets {
				position: absolute;
				top: -130px;
				left: 0;
				width: auto;
				flex-basis: 30px;
				flex-grow: 0;
				padding-left: 4px;
				transform: translateX(-25px);
				.mot {
				    top: 100px;
				    left: 30px;
				    transform-origin: 0% 50%;
				    transform: rotate(-90deg);
				}

				.ep1 {
					position: absolute;
					left: 0px;
					top: 80px;
					height: 300px;
				}
				.ep2 {
					top: 20%;
					height: 600px;
				}
			}

			.form_container {
				width: 100%;

				.submit {
					cursor: pointer;
					float: right;
					color: $color_black;
					font-weight: 700;
					text-transform: uppercase;
					text-decoration: none;
					padding-bottom: 4px;
					border: none;
					border-bottom: 2px solid $color_black;
					background: none;
					border-radius: 0;
				}
				.submit:hover {
					color: $color_black;
					text-decoration: none;
				}
				.submit:disabled {
					cursor: initial;
					color: lighten($color_black, 50%);
					border-bottom: 2px solid lighten($color_black, 50%);
				}

				textarea {
					height: 200px;
				}
			}
		}
	}

	#section3 {
		margin-top: 50px;

		.titre_type_1 {
			text-align: left;
			margin-left: 15vw;

			.part1 {
				font-family: "questa-grande";
				font-size: 2rem;
				font-weight: 300;
			}
			.part2 {
				font-size: 4rem;
				margin-left: 40px;
			}
		}
	}

	#section4 {
		margin-top: 20px;
		margin-bottom: 50px;
		p { font-size: 0.85rem; }

		.col2 {
			border-left: 1px solid $color_black;
			border-right: 1px solid $color_black;
		}

		> div { padding: 0 30px; }
	}

	#section5 {
		margin-top: 150px;

		.cadre {
			position: absolute;
			left: 10vw;
			bottom: 0;
			z-index: 10;
			padding: 20vw;
			border: 2px solid $color_black;
			transform: translateY(30%);

			img {
				position: absolute;
				width: 40%;
				top: 0;
				right: 5%;
			}
			.img1 { transform: translateY(-80%); }
			.img2 { transform: translateY(-185%); }

			h2 {
				position: absolute;
				right: -120px;
				top: 20%;
				color: $color_white;
				line-height: 3rem;
				.part2 { font-size: 4.5rem; }
				.part1 {
					font-size: 1rem;
					margin-right: 200px;
				}
			}
		}
	}

	#section6 {
		margin: 80px 0;

		.zone_photos {
			position: relative;
			margin-left: 20%;
			width: 50%;
			height: 32vw;

			.img3 {
				position: absolute;
				z-index: 11;
				top: 0;
				left: 0;
				width: 25%;
			}
			.img4 {
				position: absolute;
				z-index: 11;
				top: 0;
				left: 27%;
				width: 25%;
			}
			.img5 {
				position: absolute;
				z-index: 10;
				bottom: 0;
				right: 0;
				width: 80%;
			}

			a { margin-right: 80px; }
		}

		.filets {
			position: absolute;
			top: -200px;
			right: 20vw;
			.mot {
			    top: 180px;
			    right: 30px;
			    transform-origin: 100% 50%;
			    transform: rotate(-90deg);
			}
			.ep2 {
				top: 0;
				height: 500px;
			}
		}
	}

	#section7 {

	}
}


@include media-breakpoint-down($responsive_breakpoint) {
	.contact_acces {
		#section1 {
			.absolute_centered {
				width: 90%;
			}
		}
	}
}