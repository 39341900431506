
.modal {
    padding-right: 0px !important;
}

.modal-dialog.centered {
	margin: 0 !important;
	@extend %absolutecenter_xy;
	transform: translate(-50%, -50%) !important;
}

// MODALE MINIMALISTE, BORDERLESS ETC
.modal.bare {
    

    .modal-dialog {
    	
    	.modal-content {
    		border-radius: 0 !important;
    		border: none;

    		button.close {
    			cursor: pointer;
    			position: absolute;
    			z-index: 10;
    			top: 10px;
    			right: 10px;
    			font-size: 3rem;
    			line-height: 0.5em;
    			color: $color_black;
    		}

    		.modal-body {
    			padding: 0;
    		}
    	}
    }
}

// MODALE AVEC CAROUSEL DEDANS
.carousel_modal {

	.modal-dialog {
		width: 80vw !important;
		height: 80vh !important;
		max-width: none;

		.modal-content {
			height: 100%;
		}
	}

	.carousel {
		height: 80vh !important;
	}

}

// MODALE AVEC VIDEO DEDANS
$video_modal_width: 70vw;
.video_modal {

	.modal-dialog {
		width: $video_modal_width !important;
		height: $video_modal_width * 9 / 16 !important;
		max-width: none;

		.modal-content {
			height: 100%;

			video {
				width: 100%;
			}

			iframe {
				width: 100%;
				height: 100%;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	.carousel {
		height: 100%;
	}

}


// Modale confirmation contact
.form_error_box {
    .modal-title {
        font-weight: 700;
    }

    .form_modal_ok {
        color: $color_success;
    }

    .form_modal_fail {
        color: $color_error;
    }

    i {
        float: left;
        margin-right: 16px;
        font-size: 3rem;
        margin-bottom: 16px;
    }

    h6 {
        margin-top: 26px;
        font-weight: 700;
    }

    ul {
        padding: 0;
        list-style: none;

        li {
            border-top: 1px solid #e0e0e0;
            font-size: 0.9rem;
            padding-top: 8px;
            margin-bottom: 10px;
        }
    }
}


// MODALE EVENT


// LIGHTBOX RESERVATION
#modal_event {

	.modal-dialog, .modal-content {
		height: auto;
		width: 100%;
		max-width: 500px;
	}

	.modal-content {
		position: relative;
		padding: 30px;
		min-height: 100px;
		text-align: center;

		.close span { color: $color_black !important; }

		h3 {
			color: $color_black;
			text-align: center;
			font-size: 1rem;
			font-weight: 300;
			margin: 0;
		}

		.filet {
			display: inline-block;
			height: 2px;
			background-color: $color_black;
			width: 100px;
			margin: 14px 0;
		}		

		p {
			margin: 0;
		}

		h2 {
			margin-bottom: 30px;
		}

		h5 {
			margin-bottom: 30px;
			font-size: 1.5rem;
		}

		img {
			display: block;
			width: auto;
			margin: auto;
			margin-bottom: 30px;

		}

		.grey {
			color: lighten($color_black, 10%);
		}

		.bouton {
			cursor: pointer;
			text-transform: none;
			border: none;
			background-color: transparent;
			border-bottom: 2px solid #000000;
		}

		.content_container {
			position: relative;
			margin-top: 10px;
			margin-bottom: 0px;

			.filet {
				height: 1px;
			}
		}

		
	}
}