
// -----------------------------------
// ||         Restaurant         ||
// -----------------------------------

.restaurant {
	#section1 {

	}

	#section2 {
		.col_gauche {
			img {
				width: 100%;
			}
		}

		.col_droite {
			.filets {
				flex-basis: 30px;
				flex-grow: 0;
				padding-left: 6px;
				.mot {
				    top: 50px;
				    left: 30px;
				    transform-origin: 0% 50%;
				    transform: rotate(-90deg);
				}

				.ep1 {
					position: absolute;
					left: 3px;
					bottom: 0;
					height: 70%;
				}
				.ep2 {
					top: -20%;
					height: 120%
				}
			}

			.surtexte {
				padding: 5vw;
				background-color: $color_grisclair;
				font-size: 1.5rem;
			}

			.paragraphe_gros {
				padding: 5vw;
			}
		}
	}

	#section3 {
		.col_gauche { padding-right: 20px; }
		.col_droite { padding-left: 20px; }
	}

	#section4 {
		z-index: 10;
		margin-top: 50px;

		.col_gauche {
			padding-right: 5%;
		}

		.col_droite {
			img { width: 100%; }
			.img2 {
				position: absolute;
				left: 0;
				bottom: -10px;
				transform: translateY(100%);
			}
		}
	}

	#section5 {
		margin-top: 70px;

		.zone_haut {
			height: 60vh;
			img { 
				width: 100%; 
				height: 100%;
				object-fit: cover;
			}

			.cadre_couleur {
				position: absolute;
				z-index: 1000;
				left: 21.3%;
				top: -20px;
				width: 28.8%;
				padding: 30px;
				border: 1px solid $color_black;
				h2 { color: $color_white; }

				.zone_dans_image {
					position: relative;
					height: 60vh;
					h2 { @extend %absolutecenter_xy; }
				}

				.textes {
					height: 300px;
				}

				/* height: calc(60vh + 350px); */
			}
		}
		.zone_bas {
			position: relative;
			min-height: 600px;

			img {
				position: absolute;
				width: 15vw;
				top: -20px;
				right: 6%;
			}

			.filets {
				z-index: 1000;
				position: absolute;
				right: 25%;

				.mot {
				    top: 50px;
				    right: 30px;
				    transform-origin: 100% 50%;
				    transform: rotate(-90deg);
				}
				.ep2 {
					z-index: 1000;
					top: -50px;
					height: 300px;
				}
			}
		}
	}
}


@include media-breakpoint-down($responsive_breakpoint) {
	.restaurant {
		#section1 {
			.absolute_centered {
				width: 90%;
			}
		}
		
		#section2 {
			.col_gauche {
				img {
					height: 40vh;
					object-fit: cover;
				}
			}
		}

		#section3 {
			.col_droite {
				padding: 0;
			}
		}

		#section4 {
			a {
				margin-top: 20px !important;
			}

			.img2 {
				bottom: auto !important;
			}
		}

		#section5 {
			margin-top: 100px;
			margin-bottom: 100px;

			.zone_haut {
				img {
					height: auto;
					width: 100%;
				}

				.cadre_couleur {
					position: relative !important;
					top: 20px;
					left: 5%;
					width: 90%;

					img {
						width: 50%;
					}

					.photos {
						display: flex;
						position: relative;
						width: 100%;
					}

					a {
						/* float: none !important; */
					}
				}

				.zone_dans_image {
					display: none;
				}
			}

			.zone_bas {
				min-height: 0px;
				.img2 { display: none; }

			}
		}
	}
}