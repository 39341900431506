
// -----------------------------------
// ||            Accueil            ||
// -----------------------------------

.index {
	#section1 {
		height: 60vh;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		h1 {
			position: absolute;
			z-index: 100;
			top: 30vh;
			left: 7vw;
			transform: translateY(-50%);
			color: white;
			font-size: 4rem;

			span { font-family: "questa-grande"; }
			.part1 {
				font-style: italic;
			}
			.part2 {
				font-weight: 700;
			}
		}

		.reseaux {
			position: absolute;
			right: 40px;
			bottom: 40px;

			p {
				margin: 0;
				font-size: 1.4rem;
			}
			a:hover {
				color: $color_white;
			}
		}
	}

	#section2 {
		background-color: $color_grisclair;

		.col1 {
			padding: 5vw;
			text-align: center;

			p {
				margin: 5px;
				font-size: 1.3rem;
			}

			h1 {
				color: white;
				opacity: 0;
				font-size: 3.5rem;
				span { font-family: "questa-grande"; }
				.part1 {
					font-style: italic;
				}
				.part2 {
					font-weight: 700;
				}
				transition: 0.8s;
			}
			h1:hover { opacity: 1; }

			h5 {
				font-weight: 700;
				margin-top: 30px;
			}

			.yvoire {
				margin-top: 20px;
				font-size: 0.8rem;
				font-weight: 700;
			}

			.filet {
				margin: auto;
				height: 2px;
				width: 46px;
				background-color: $color_black;
			}
		}

		.col2 {
			flex-grow: 0.3;
			padding: 30px 0 !important;

			.filet_vertical._1 {
				top: -50px;
				height: 70%;

				.mot {
					bottom: 0px;
					right: 30px;
					transform-origin: 100% 50%;
					transform: rotate(-90deg);
				}
			}

			.filet_vertical._2 {
				top: -200px;

				.mot {
					top: -60px;
					left: 30px;
					transform-origin: 0 50%;
					transform: rotate(-90deg) translateX(-100%);
					color: white;
				}
			}
		}

		.col3 {
			flex-grow: 6;
			.nav_accueil {
				padding: 16px 30px;
				justify-content: space-between;

				a {
					font-size: 0.9rem;
					font-weight: 300;
					text-decoration: none;
					text-transform: uppercase;
					border-bottom: none;
					border-bottom: 3px solid transparent;
					transition: 0.1s;
				}
				a:hover {
					color: $color_black;
					border-bottom: 3px solid $color_black;
				}
			}

			.carousel {
				transform: translateY(20px);
				box-shadow: 10px 10px 30px rgba($color_black, 0.3);
			}
		}
	}

	#section3 {
		z-index: -1;
		padding-top: 10vw;
		padding-bottom: 3vw;

		div > div {
			margin-left: 6px;

			img {
				width: 100%;
			}
		}

		.img1 {
			transform: translateY(50%);
		}
	}

	#section4 {
		padding: 0 5vw;
		
		.container {
			flex-wrap: nowrap !important;
		}

		.col {
			flex: 1;
		}

		.filets {
			flex: 0.04;
			.filet_vertical._1 {
				bottom: -30%;
				height: 50%;

				.mot {
					bottom: -100px;
					left: 20px;
					transform-origin: 0% 50%;
					transform: rotate(-90deg);
				}
			}
		}

		.image_decoupe_container {
			margin-top: 100px;

			.bande {
				overflow: hidden;
				height: auto;
				width: 20%;

				img {
					position: relative;
					width: 500%;
				}
			}

			.bande._1 img { left: -0%; }
			.bande._2 img { left: -100%; }
			.bande._3 img { left: -200%; }
			.bande._4 img { left: -300%; }
			.bande._5 img { left: -400%; }
		}

		.col.texte {
			h2 {
				color: $color_black;
				text-align: right;

				.part1 {
					font-size: 1.3rem;
				}
				.part2 {
					font-family: "questa-grande";
					font-size: 3rem;
					font-weight: 700;
				}
			}

		}
	}

	#section5 {
		height: 100vh;
		margin-top: 60px;

		h2 {
			position: absolute;
			z-index: 12;
			bottom: 20px;
			left: 60vw;
			color: $color_white;
			text-align: right;
			.part1 {
				font-size: 1.3rem;
			}
			.part2 {
				font-family: "questa-grande";
				font-size: 3rem;
				font-weight: 700;
			}
		}

		.img {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}

		.img.noirblanc {
			z-index: 10;
			background: url(../images/accueil_img_changement_noirblanc_2023.jpg) no-repeat center left;
			background-size: 100vw;
		}

		.img.couleur {
			z-index: 11;
			background: url(../images/accueil_img_changement_couleur_2023.jpg) no-repeat center left;
			background-size: 100vw;
			width: 0%;
			/* border-right: 2px solid $color_white; */
		}

		.transition {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 30px;
			/* background: linear-gradient(to right, transparent, rgba($color_white, 0.5)); */
			border-right: 2px solid $color_white;
		}
	}

	#section6 {
		z-index: 100;
		margin-top: 20px;

		$decalage_images: 10px;
		.images {
			img { width: 100%; }

			.img2 {
				position: absolute;
				left: 0;
				top: $decalage_images;
				transform: translateY(100%);
			}

			.img3 {
				position: absolute;
				top: $decalage_images;
				left: $decalage_images;
				transform: translate(100%, 100%);
			}

		}

		.texte {
			.filets {
				z-index: 10000;
				flex-basis: 30px;
				flex-grow: 0;
				padding: 0 10px;

				.filet_vertical._1 {
					top: -60%;
					height: 160%;

					.mot {
						color: $color_white;
						top: 0px;
						right: 20px;
						transform-origin: 100% 50%;
						transform: rotate(-90deg);
					}
				}
			}

			.texte {
				padding-left: 30px;
				padding-top: 30px;
				padding-right: 10%;
			}
		}
	}

	#section7 {
		margin-top: 160px;
		color: $color_black;

		.image {
			padding: 30px 0;
			img {
				width: 100%;
				transform: translateX(15%);
			}
		}

		.titre {
			text-align: right;
			border: 1px solid $color_black;
			padding: 40px;
			padding-left: 150px;
			p {
				margin-top: 20px;
				margin-bottom: 0;
			}
		}
	}

	#section8 {
		margin-top: 70px;
		margin-bottom: 50px;

		.col-4 {
			img {
				width: 95%;
				transform: translateY(50%);
			}
		}

		.titrage {
			justify-content: space-between;
			h2 { align-self: flex-end; }
			img { width: auto; }
		}

		.texte {
			margin-top: 20px;
			padding-left: 5%;
		}
	}


	#section10 {
		margin-top: 150px;

		.cadre {
			position: absolute;
			left: 10vw;
			bottom: 0;
			z-index: 10;
			padding: 20vw;
			border: 2px solid $color_black;
			transform: translateY(30%);

			img {
				position: absolute;
				width: 40%;
				top: 0;
				right: 5%;
			}
			.img1 { transform: translateY(-80%); }
			.img2 { transform: translateY(-185%); }

			h2 {
				position: absolute;
				right: -120px;
				top: 20%;
				color: $color_white;
				line-height: 3rem;
				.part2 { font-size: 4.5rem; }
				.part1 {
					font-size: 1rem;
					margin-right: 200px;
				}
			}
		}
	}

	#section11 {
		margin: 80px 0;

		.zone_photos {
			position: relative;
			margin-left: 20%;
			width: 50%;
			height: 32vw;

			.img3 {
				position: absolute;
				z-index: 11;
				top: 0;
				left: 0;
				width: 25%;
			}
			.img4 {
				position: absolute;
				z-index: 11;
				top: 0;
				left: 27%;
				width: 25%;
			}
			.img5 {
				position: absolute;
				z-index: 10;
				bottom: 0;
				right: 0;
				width: 80%;
			}

			a { margin-right: 80px; }
		}

		.filets {
			position: absolute;
			top: -200px;
			right: 20vw;
			.mot {
			    top: 180px;
			    right: 30px;
			    transform-origin: 100% 50%;
			    transform: rotate(-90deg);
			}
			.ep2 {
				top: 0;
				height: 500px;
			}
		}
	}
}

.index {
	#section2 {
		#carousel_accueil {
			.carousel-indicators {
				right: -100px;
				bottom: 26px;
			}
		}
	}
}

@include media-breakpoint-down($responsive_breakpoint) {
	.index {
		#section2 {
			flex-direction: column-reverse;

			.nav_accueil {
				flex-direction: column;
			}

			#carousel_accueil {
				height: 40vh;
				width: 80%;
				margin-left: 10%;
				margin-top: 20px;

				.carousel-indicators {
				    right: -85px;
				}
			}

			.col1 {
				margin-top: 20px;
				h1 {
					display: none;
				}
			}
		}

		#section4 {
			.container {
				flex-direction: column;
			}
		}

		#section5 {
			height: 40vh;
		}

		#section6 {
			.img3 {
				display: none;
			}
		}

		#section7 {
			margin-top: 60px;

			.image {
				img {
					transform: none;
				}
			}
		}

		#section8 {
			img {
				width: 100%;
				height: 30vh;
				object-fit: cover;
			}
		}

		#section10 {
			.cadre {
				img {
					display: none;
				}  
				top: 10px;
				bottom: 10px;
				transform: none;
				width: 80vw;

				h2 {
					left: 10px;
					right: auto;
					.part2 {
						font-size: 3.75rem !important;
					}
				}
			}
		}

		#section11 {
			margin-top: 120px;
			.zone_photos {
				margin-left: 10%;
				width: 80%;
			}

			a {
				position: relative;
				margin: 0;
				top: -130px;
			}
		}
	}
}