
// LIGHTBOX RESERVATION
#modal_galerie_reservation {

	#confirmation_reservation_ok, #confirmation_reservation_fail {
		display: none;
	}

	.modal-dialog, .modal-content {
		height: auto;
		width: 100%;
		max-width: 500px;
		top: 50%;
		transform: translateY(-50%);
	}

	.modal-content {
		position: relative;
		padding: 30px;
		min-height: 100px;

		iframe {
			min-height: 85vh;
		}


		.close span { color: $color_black !important; }

		h1 {
			text-align: center;
			color: $color_black;
			span { font-family: "questa-grande"; }
			.part1 {
				font-style: italic;
			}
			.part2 {
				font-weight: 700;
			}
		}

		h3 {
			display: inline-block;
			color: $color_black;
			text-align: center;
			margin-left: 50%;
			transform: translateX(-50%);
			font-size: 1rem;
			font-weight: 700;
			border-bottom: 2px solid $color_black;
		}

		.legals {
			position: absolute;
			left: 0;
			bottom: -56px;
			color: $color_black;
			font-size: 0.8rem;
			text-decoration: underline;
		}

		.bouton {
			cursor: pointer;
			text-transform: none;
			border: none;
			border-bottom: 2px solid #000000;
		}

		.content_container {
			position: relative;
			margin-top: 30px;
			margin-bottom: 0px;

			.content {
				pointer-events: all;
				display: block;
				opacity: 1;
				transition: opacity 0.5s;
			}
			.content.hidden {
				display: none;
				opacity: 0;
				pointer-events: none; }

			label {
				font-size: 0.9rem;
				margin-bottom: 10px;
				font-style: italic;
			}

			.form {
				select {
					width: 100%;
					border: 1px solid $color_black;
					border-radius: 0;
					color: $color_black;
				}

				.infos_perso {
					input {
						background-color: $color_grisclair;
						margin-bottom: 10px;
					}
				}

				.bouton {
					cursor: pointer;
					background: transparent;
					padding-bottom: 4px;
					border: none;
					border-bottom: 2px solid #000000;
				}

				.form-check {
					margin-bottom: 0;
				}

				.rappels {
					margin-bottom: 30px;

					p {
						margin-bottom: 0;
						font-weight: 700;

						span {
							font-weight: 500;
							color: green;
						}

						.red {
							color: red;
						}
					}
				}

				.check_full_alert {
					color: $color_rouge;
				}

				.submit:disabled {
					cursor: initial;
					color: lighten($color_black, 60%);
					border-bottom: 2px solid lighten($color_black, 60%);
				}
			}
		}

		#datepicker {
			width: 100%;
			margin-bottom: 10px;

			.ui-datepicker-header {
				position: relative;
				padding: 10px;
				border: 1px solid $color_black;

				.ui-datepicker-prev {
					cursor: pointer;
					position: absolute;
					top: 50%;
					left: 15px;
					transform: translateY(-50%);
					font-size: 1.4rem;
					font-weight: 700;
				}
				.ui-datepicker-next {
					cursor: pointer;
					position: absolute;
					top: 50%;
					right: 15px;
					transform: translateY(-50%);
					font-size: 1.4rem;
					font-weight: 700;
				}
				.ui-datepicker-title {
					text-align: center;
					font-weight: 700;
				}
			}

			.ui-datepicker-calendar {
				width: 100%;
				text-align: center;

				thead {
					background-color: $color_black;
					color: $color_white;
					th { padding: 4px; }
				}

				td {
					position: relative;
					box-sizing: border-box;
					background-clip: padding-box;
					user-select: none;
					height: 30px;
					width: 14.285714%;
					padding: 4px;
					border: 1px solid $color_black;
					font-size: 0.85rem;
					a {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						color: $color_black;
						line-height: 30px;
					}
					a:hover { text-decoration: none; }
				}

				.ui-state-active {
					background-color: $color_jaune;
				}

				.ui-datepicker-unselectable {
					pointer-events: none;
					background-color: #aaaaaa;
					border: 1px solid black;
				}
			}
		}
	}
}