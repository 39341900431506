
// -----------------------------------
// ||         Cookie notice         ||
// -----------------------------------

#cookie_notice {
	@if $cookie_notice_type == fixed { 
		position: fixed;
	}
	@if $cookie_notice_position == top { 
		top: 0;
	}
	@if $cookie_notice_position == bottom { 
		bottom: 0;
	}
	overflow: hidden;
	z-index: 1000;
	background-color: $cookie_notice_bgcolor;
	max-height: 200px;
	opacity: 1;
	transition: $cookie_notice_animation_time;

	* { 
		margin: 0; 
		font-size: 0.9rem;
	}

	.flex_container {
		display: flex;
		align-items: flex-end;
		padding: $cookie_notice_padding;
	}
	

	.texte, .texte p {
		flex: 1;
		color: $cookie_notice_txtcolor;
	}

	.lien {
		flex: initial;
		padding: 0 15px;
		
		a {
			color: $cookie_notice_txtcolor;
			text-decoration: $cookie_notice_link_decoration;
		}
	}

	.bouton {
		flex: initial;

		button {
			cursor: pointer;
			color: $cookie_notice_button_txtcolor;
			background-color: $cookie_notice_button_bgcolor;
			transition: 0.1s;
		}
		button:hover {
			background-color: $cookie_notice_button_bgcolor_hover;
		}
	}



}

#cookie_notice.hide {
	@if $cookie_notice_animation == opacity { 
		opacity: 0;
	}
	@if $cookie_notice_animation == unfold { 
		max-height: 0;
	}
}

.cookies_text {
	padding: 4vw;

	p {
		margin-bottom: 20px;
	}
	h6 {
		margin-top: 30px;
	}
	h6:first-child {
		margin-top: 0px;
	}

	table {
		td {
			padding-right: 20px;
		}
	}
}



@include media-breakpoint-down($responsive_breakpoint) {

	#cookie_notice {
		max-height: 400px;

		.flex_container {
			flex-direction: column;
			align-items: flex-start;

			> div {
				margin-bottom: 16px;
			}
		}

		.lien {
			padding: 0;
		}


	}

}