
// ----------------------------------
// ||            Footer            ||
// ----------------------------------


// ----------------- CSS SPECIFIQUE AU SITE -----------------

footer {
	
	.mentions {
		color: $color_white;
		font-size: 0.7rem;
		margin: 0;
	}

	.contact_col {
		text-align: center;

		.centered {
			display: block;
			width: auto;
			text-align: left;
			padding-left: 30px;
		}

		p {
			color: $color_white;
			margin: 0;
			line-height: 1rem;
		}

		h5 {
			margin: 0;
			margin-top: 30px;
			font-weight: 700;
		}

		.reseaux {
			margin-top: 30px;
			display: flex;
			justify-content: self-end;

			.filet {
				width: 140px;
				margin-top: 15px;
				height: 1px;
				background-color: $color_white;
			}

			i {
				margin-left: 10px;
			}
		}
	}

	#newsletter_container {
		h6 { 
			font-size: 0.8rem;
			font-weight: 700;
		}

		.champs_newsletter {
			width: 100%;
			border: none;
		}

		#newsletter_submit_btn {
			cursor: pointer;
			border: none;
			margin-top: 5px;
			float: right;
			background: transparent;
			color: white;
			font-weight: 700;
			border-bottom: 2px solid $color_white;
			transition: border 0.2s;
		}
		#newsletter_submit_btn:hover {
			border-bottom: 5px solid $color_white;
		}
	}

}

@include media-breakpoint-down($responsive_breakpoint) {
	.centered {
		padding-left: 0 !important;
	}
}

// ----------------------------------------------------------


// FOOTER - GÉNÉRAL
footer {
	background-color: $footer_bgcolor;
	padding: $footer_padding;
	.separateur { width: $footer_separateur_horizontal; }
	color: $footer_txtcolor;
	a {
		color: $footer_link_color;
		@if $footer_link_underline == 1 { text-decoration: underline; }
	}
	a:hover {
		color: $footer_link_hover_color;
	}
}


@include media-breakpoint-down($responsive_breakpoint) {

	footer {
		flex-direction: column-reverse;
		
		.separateur { width: 100%; height: $footer_separateur_vertical; }
	}

}