@charset "UTF-8";
.restaurant #section5 .zone_haut .cadre_couleur .zone_dans_image h2, .modal-dialog.centered, #carousel_galerie_accueil .carousel-indicators li.active .carousel-indicator-inside, #carousel_galerie_accueil .carousel-indicators li .carousel-indicator-inside, #carousel_galerie_contact .carousel-indicators li.active .carousel-indicator-inside, #carousel_galerie_contact .carousel-indicators li .carousel-indicator-inside, #carousel_accueil .carousel-indicators li.active .carousel-indicator-inside, #carousel_accueil .carousel-indicators li .carousel-indicator-inside, .nav-item.icon .nav_icon_container i, .absolute_centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.actualites #section2 .col_droite .news_container .row .col_image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #868e96;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #868e96;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}
article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role=button],
input:not([type=range]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 5px;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #868e96;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #868e96;
}

code,
kbd,
pre,
samp {
  font-family: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #bd4147;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
}
a > code {
  padding: 0;
  color: inherit;
  background-color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 90%;
  color: #212529;
}
pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  border-radius: 0;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e9ecef;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e9ecef;
}
.table tbody + tbody {
  border-top: 2px solid #e9ecef;
}
.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e9ecef;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #e9ecef;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dddfe2;
}

.table-hover .table-secondary:hover {
  background-color: #cfd2d6;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #cfd2d6;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #e9ecef;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm.table-bordered {
    border: 0;
  }
}
@media (max-width: 767px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md.table-bordered {
    border: 0;
  }
}
@media (max-width: 991px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg.table-bordered {
    border: 0;
  }
}
@media (max-width: 1199px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl.table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table-responsive.table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #868e96;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.col-form-legend {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  font-size: 1rem;
}

.form-control-plaintext {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .form-control-plaintext.input-group-addon,
.input-group-sm > .input-group-btn > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .form-control-plaintext.input-group-addon,
.input-group-lg > .input-group-btn > .form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > select.input-group-addon:not([size]):not([multiple]),
.input-group-sm > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-addon:not([size]):not([multiple]),
.input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.5rem;
}
.form-check.disabled .form-check-label {
  color: #868e96;
}

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0;
}

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem;
}

.form-check-inline {
  display: inline-block;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-label {
  vertical-align: middle;
}

.valid-feedback {
  display: none;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: 0.2rem;
}

.was-validated .form-control:valid, .form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid + .form-check-label, .form-check-input.is-valid + .form-check-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-indicator, .custom-control-input.is-valid ~ .custom-control-indicator {
  background-color: rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid ~ .custom-control-description, .custom-control-input.is-valid ~ .custom-control-description {
  color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-control, .custom-file-input.is-valid ~ .custom-file-control {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid ~ .custom-file-control::before, .custom-file-input.is-valid ~ .custom-file-control::before {
  border-color: inherit;
}
.was-validated .custom-file-input:valid:focus, .custom-file-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: 0.2rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid + .form-check-label, .form-check-input.is-invalid + .form-check-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-indicator, .custom-control-input.is-invalid ~ .custom-control-indicator {
  background-color: rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid ~ .custom-control-description, .custom-control-input.is-invalid ~ .custom-control-description {
  color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-control, .custom-file-input.is-invalid ~ .custom-file-control {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid ~ .custom-file-control::before, .custom-file-input.is-invalid ~ .custom-file-control::before {
  border-color: inherit;
}
.was-validated .custom-file-input:invalid:focus, .custom-file-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
  }
  .form-inline .form-check-label {
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }
  .form-inline .custom-control-indicator {
    position: static;
    display: inline-block;
    margin-right: 0.25rem;
    vertical-align: text-bottom;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn:focus, .btn:hover {
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active {
  background-image: none;
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #727b84;
  border-color: #6c757d;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #868e96;
  border-color: #868e96;
}
.btn-secondary:not([disabled]):not(.disabled):active, .btn-secondary:not([disabled]):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #666e76;
  box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not([disabled]):not(.disabled):active, .btn-success:not([disabled]):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not([disabled]):not(.disabled):active, .btn-info:not([disabled]):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #111;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #111;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not([disabled]):not(.disabled):active, .btn-warning:not([disabled]):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #111;
  background-color: #d39e00;
  border-color: #c69500;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not([disabled]):not(.disabled):active, .btn-danger:not([disabled]):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #111;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #111;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not([disabled]):not(.disabled):active, .btn-light:not([disabled]):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #111;
  background-color: #dae0e5;
  border-color: #d3d9df;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not([disabled]):not(.disabled):active, .btn-dark:not([disabled]):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not([disabled]):not(.disabled):active, .btn-outline-primary:not([disabled]):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #868e96;
  background-color: transparent;
  background-image: none;
  border-color: #868e96;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #868e96;
  background-color: transparent;
}
.btn-outline-secondary:not([disabled]):not(.disabled):active, .btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
  box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not([disabled]):not(.disabled):active, .btn-outline-success:not([disabled]):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not([disabled]):not(.disabled):active, .btn-outline-info:not([disabled]):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not([disabled]):not(.disabled):active, .btn-outline-warning:not([disabled]):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not([disabled]):not(.disabled):active, .btn-outline-danger:not([disabled]):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not([disabled]):not(.disabled):active, .btn-outline-light:not([disabled]):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not([disabled]):not(.disabled):active, .btn-outline-dark:not([disabled]):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}
.btn-link:focus, .btn-link.focus {
  border-color: transparent;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #868e96;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}
.fade.show {
  opacity: 1;
}

.collapse {
  display: none;
}
.collapse.show {
  display: block;
}

tr.collapse.show {
  display: table-row;
}

tbody.collapse.show {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
}
.dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #868e96;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #868e96;
  white-space: nowrap;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 2;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 2;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}
.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn-group {
  float: left;
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.btn + .dropdown-toggle-split::after {
  margin-left: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}
.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

[data-toggle=buttons] > .btn input[type=radio],
[data-toggle=buttons] > .btn input[type=checkbox],
[data-toggle=buttons] > .btn-group > .btn input[type=radio],
[data-toggle=buttons] > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
}
.input-group .form-control {
  position: relative;
  z-index: 2;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group .form-control:focus, .input-group .form-control:active, .input-group .form-control:hover {
  z-index: 3;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: flex;
  align-items: center;
}
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-addon,
.input-group-btn {
  white-space: nowrap;
}

.input-group-addon {
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-addon.form-control-sm,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .input-group-addon.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.input-group-addon.form-control-lg,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .input-group-addon.btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.input-group-addon input[type=radio],
.input-group-addon input[type=checkbox] {
  margin-top: 0;
}

.input-group .form-control:not(:last-child),
.input-group-addon:not(:last-child),
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group > .btn,
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-addon:not(:last-child) {
  border-right: 0;
}

.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-control + .input-group-addon:not(:first-child) {
  border-left: 0;
}

.input-group-btn {
  position: relative;
  align-items: stretch;
  font-size: 0;
  white-space: nowrap;
}
.input-group-btn > .btn {
  position: relative;
}
.input-group-btn > .btn + .btn {
  margin-left: -1px;
}
.input-group-btn > .btn:focus, .input-group-btn > .btn:active, .input-group-btn > .btn:hover {
  z-index: 3;
}
.input-group-btn:first-child > .btn + .btn {
  margin-left: 0;
}
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group {
  margin-right: -1px;
}
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group {
  z-index: 2;
  margin-left: 0;
}
.input-group-btn:not(:first-child) > .btn:first-child,
.input-group-btn:not(:first-child) > .btn-group:first-child {
  margin-left: -1px;
}
.input-group-btn:not(:first-child) > .btn:focus, .input-group-btn:not(:first-child) > .btn:active, .input-group-btn:not(:first-child) > .btn:hover,
.input-group-btn:not(:first-child) > .btn-group:focus,
.input-group-btn:not(:first-child) > .btn-group:active,
.input-group-btn:not(:first-child) > .btn-group:hover {
  z-index: 3;
}

.custom-control {
  position: relative;
  display: inline-flex;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-indicator {
  color: #fff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:active ~ .custom-control-indicator {
  color: #fff;
  background-color: #b3d7ff;
}
.custom-control-input:disabled ~ .custom-control-indicator {
  background-color: #e9ecef;
}
.custom-control-input:disabled ~ .custom-control-description {
  color: #868e96;
}

.custom-control-indicator {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  user-select: none;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-indicator {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #007bff;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-indicator {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-controls-stacked {
  display: flex;
  flex-direction: column;
}
.custom-controls-stacked .custom-control {
  margin-bottom: 0.25rem;
}
.custom-controls-stacked .custom-control + .custom-control {
  margin-left: 0;
}

.custom-select {
  display: inline-block;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: none;
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple] {
  height: auto;
  background-image: none;
}
.custom-select:disabled {
  color: #868e96;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-file {
  position: relative;
  display: inline-block;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  min-width: 14rem;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-control {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem #007bff;
}

.custom-file-control {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-control:lang(en):empty::after {
  content: "Choose file...";
}
.custom-file-control::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  z-index: 6;
  display: block;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}
.custom-file-control:lang(en)::before {
  content: "Browse";
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:focus, .nav-link:hover {
  text-decoration: none;
}
.nav-link.disabled {
  color: #868e96;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #ddd;
}
.nav-tabs .nav-link.disabled {
  color: #868e96;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #ddd #ddd #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:focus, .navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.5rem 0.5rem;
  font-size: 1.25rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:focus, .navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 767px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 991px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 1199px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}
.card-group .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group .card:first-child .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group .card:last-child .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0;
  }
  .card-group .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group .card:only-child .card-img-top {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group .card:only-child .card-img-bottom {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #868e96;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #868e96;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #868e96;
  pointer-events: none;
  background-color: #fff;
  border-color: #ddd;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #ddd;
}
.page-link:focus, .page-link:hover {
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #ddd;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
.badge-primary[href]:focus, .badge-primary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #868e96;
}
.badge-secondary[href]:focus, .badge-secondary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #6c757d;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
.badge-success[href]:focus, .badge-success[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
.badge-info[href]:focus, .badge-info[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #111;
  background-color: #ffc107;
}
.badge-warning[href]:focus, .badge-warning[href]:hover {
  color: #111;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
.badge-danger[href]:focus, .badge-danger[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #111;
  background-color: #f8f9fa;
}
.badge-light[href]:focus, .badge-light[href]:hover {
  color: #111;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
.badge-dark[href]:focus, .badge-dark[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #464a4e;
  background-color: #e7e8ea;
  border-color: #dddfe2;
}
.alert-secondary hr {
  border-top-color: #cfd2d6;
}
.alert-secondary .alert-link {
  color: #2e3133;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #007bff;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:focus, .list-group-item-action:hover {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item:focus, .list-group-item:hover {
  text-decoration: none;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #868e96;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

a.list-group-item-primary,
button.list-group-item-primary {
  color: #004085;
}
a.list-group-item-primary:focus, a.list-group-item-primary:hover,
button.list-group-item-primary:focus,
button.list-group-item-primary:hover {
  color: #004085;
  background-color: #9fcdff;
}
a.list-group-item-primary.active,
button.list-group-item-primary.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #464a4e;
  background-color: #dddfe2;
}

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: #464a4e;
}
a.list-group-item-secondary:focus, a.list-group-item-secondary:hover,
button.list-group-item-secondary:focus,
button.list-group-item-secondary:hover {
  color: #464a4e;
  background-color: #cfd2d6;
}
a.list-group-item-secondary.active,
button.list-group-item-secondary.active {
  color: #fff;
  background-color: #464a4e;
  border-color: #464a4e;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

a.list-group-item-success,
button.list-group-item-success {
  color: #155724;
}
a.list-group-item-success:focus, a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover {
  color: #155724;
  background-color: #b1dfbb;
}
a.list-group-item-success.active,
button.list-group-item-success.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

a.list-group-item-info,
button.list-group-item-info {
  color: #0c5460;
}
a.list-group-item-info:focus, a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
  color: #0c5460;
  background-color: #abdde5;
}
a.list-group-item-info.active,
button.list-group-item-info.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

a.list-group-item-warning,
button.list-group-item-warning {
  color: #856404;
}
a.list-group-item-warning:focus, a.list-group-item-warning:hover,
button.list-group-item-warning:focus,
button.list-group-item-warning:hover {
  color: #856404;
  background-color: #ffe8a1;
}
a.list-group-item-warning.active,
button.list-group-item-warning.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

a.list-group-item-danger,
button.list-group-item-danger {
  color: #721c24;
}
a.list-group-item-danger:focus, a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover {
  color: #721c24;
  background-color: #f1b0b7;
}
a.list-group-item-danger.active,
button.list-group-item-danger.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

a.list-group-item-light,
button.list-group-item-light {
  color: #818182;
}
a.list-group-item-light:focus, a.list-group-item-light:hover,
button.list-group-item-light:focus,
button.list-group-item-light:hover {
  color: #818182;
  background-color: #ececf6;
}
a.list-group-item-light.active,
button.list-group-item-light.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

a.list-group-item-dark,
button.list-group-item-dark {
  color: #1b1e21;
}
a.list-group-item-dark:focus, a.list-group-item-dark:hover,
button.list-group-item-dark:focus,
button.list-group-item-dark:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}
a.list-group-item-dark.active,
button.list-group-item-dark.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:focus, .close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

button.close {
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}
.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
  pointer-events: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 15px;
  margin: -15px -15px -15px auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #e9ecef;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 5px;
  height: 5px;
}
.tooltip .arrow::before {
  position: absolute;
  border-color: transparent;
  border-style: solid;
}
.tooltip.bs-tooltip-top, .tooltip.bs-tooltip-auto[x-placement^=top] {
  padding: 5px 0;
}
.tooltip.bs-tooltip-top .arrow, .tooltip.bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.tooltip.bs-tooltip-top .arrow::before, .tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 5px 5px 0;
  border-top-color: #000;
}
.tooltip.bs-tooltip-right, .tooltip.bs-tooltip-auto[x-placement^=right] {
  padding: 0 5px;
}
.tooltip.bs-tooltip-right .arrow, .tooltip.bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
}
.tooltip.bs-tooltip-right .arrow::before, .tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before {
  margin-top: -3px;
  content: "";
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}
.tooltip.bs-tooltip-bottom, .tooltip.bs-tooltip-auto[x-placement^=bottom] {
  padding: 5px 0;
}
.tooltip.bs-tooltip-bottom .arrow, .tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.tooltip.bs-tooltip-bottom .arrow::before, .tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.tooltip.bs-tooltip-left, .tooltip.bs-tooltip-auto[x-placement^=left] {
  padding: 0 5px;
}
.tooltip.bs-tooltip-left .arrow, .tooltip.bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
}
.tooltip.bs-tooltip-left .arrow::before, .tooltip.bs-tooltip-auto[x-placement^=left] .arrow::before {
  right: 0;
  margin-top: -3px;
  content: "";
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}
.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  border-color: transparent;
  border-style: solid;
}
.popover .arrow::before {
  content: "";
  border-width: 0.8rem;
}
.popover .arrow::after {
  content: "";
  border-width: 0.8rem;
}
.popover.bs-popover-top, .popover.bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.8rem;
}
.popover.bs-popover-top .arrow, .popover.bs-popover-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^=top] .arrow::before,
.popover.bs-popover-top .arrow::after,
.popover.bs-popover-auto[x-placement^=top] .arrow::after {
  border-bottom-width: 0;
}
.popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^=top] .arrow::before {
  bottom: -0.8rem;
  margin-left: -0.8rem;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.popover.bs-popover-top .arrow::after, .popover.bs-popover-auto[x-placement^=top] .arrow::after {
  bottom: calc((0.8rem - 1px) * -1);
  margin-left: -0.8rem;
  border-top-color: #fff;
}
.popover.bs-popover-right, .popover.bs-popover-auto[x-placement^=right] {
  margin-left: 0.8rem;
}
.popover.bs-popover-right .arrow, .popover.bs-popover-auto[x-placement^=right] .arrow {
  left: 0;
}
.popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^=right] .arrow::before,
.popover.bs-popover-right .arrow::after,
.popover.bs-popover-auto[x-placement^=right] .arrow::after {
  margin-top: -0.8rem;
  border-left-width: 0;
}
.popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^=right] .arrow::before {
  left: -0.8rem;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.popover.bs-popover-right .arrow::after, .popover.bs-popover-auto[x-placement^=right] .arrow::after {
  left: calc((0.8rem - 1px) * -1);
  border-right-color: #fff;
}
.popover.bs-popover-bottom, .popover.bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.8rem;
}
.popover.bs-popover-bottom .arrow, .popover.bs-popover-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^=bottom] .arrow::before,
.popover.bs-popover-bottom .arrow::after,
.popover.bs-popover-auto[x-placement^=bottom] .arrow::after {
  margin-left: -0.8rem;
  border-top-width: 0;
}
.popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^=bottom] .arrow::before {
  top: -0.8rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.popover.bs-popover-bottom .arrow::after, .popover.bs-popover-auto[x-placement^=bottom] .arrow::after {
  top: calc((0.8rem - 1px) * -1);
  border-bottom-color: #fff;
}
.popover.bs-popover-bottom .popover-header::before, .popover.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 20px;
  margin-left: -10px;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.popover.bs-popover-left, .popover.bs-popover-auto[x-placement^=left] {
  margin-right: 0.8rem;
}
.popover.bs-popover-left .arrow, .popover.bs-popover-auto[x-placement^=left] .arrow {
  right: 0;
}
.popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^=left] .arrow::before,
.popover.bs-popover-left .arrow::after,
.popover.bs-popover-auto[x-placement^=left] .arrow::after {
  margin-top: -0.8rem;
  border-right-width: 0;
}
.popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^=left] .arrow::before {
  right: -0.8rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.popover.bs-popover-left .arrow::after, .popover.bs-popover-auto[x-placement^=left] .arrow::after {
  right: calc((0.8rem - 1px) * -1);
  border-left-color: #fff;
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}
.carousel-control-prev:focus, .carousel-control-prev:hover,
.carousel-control-next:focus,
.carousel-control-next:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5);
}
.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #868e96 !important;
}

a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:focus, a.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:focus, a.bg-info:hover {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:focus, a.bg-light:hover {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus, a.bg-dark:hover {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e9ecef !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #868e96 !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
.d-print-block {
  display: none !important;
}
@media print {
  .d-print-block {
    display: block !important;
  }
}

.d-print-inline {
  display: none !important;
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
}

.d-print-inline-block {
  display: none !important;
}
@media print {
  .d-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:focus, a.text-primary:hover {
  color: #0062cc !important;
}

.text-secondary {
  color: #868e96 !important;
}

a.text-secondary:focus, a.text-secondary:hover {
  color: #6c757d !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:focus, a.text-success:hover {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:focus, a.text-info:hover {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:focus, a.text-warning:hover {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:focus, a.text-danger:hover {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus, a.text-light:hover {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus, a.text-dark:hover {
  color: #1d2124 !important;
}

.text-muted {
  color: #868e96 !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

html {
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  width: 100%;
}

body {
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
  width: 100%;
  padding-right: 0 !important;
}

.modal {
  padding-right: 0 !important;
}

section {
  position: relative;
  width: 100%;
}

section a {
  color: #000000;
  font-weight: 700;
  text-decoration: underline;
}

section a:hover {
  color: #000000;
  font-weight: 700;
  text-decoration: underline;
}

img {
  width: 100%;
}

.container.row {
  margin: auto;
}

.vertical_center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.padding_side_page {
  padding: 0 5vw;
}

.hidden {
  display: none;
}

.popover-header {
  display: flex !important;
}
.popover-header .titre {
  flex: 1;
}

.popover .close {
  cursor: pointer;
  float: right;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.admin_link {
  position: absolute;
  z-index: 10000;
  background-color: transparent;
  border-top: 26px solid #343a40;
  border-left: 26px solid #343a40;
  border-right: 26px solid transparent;
  border-bottom: 26px solid transparent;
  top: -36px;
  left: -36px;
  animation-name: admin_link_start;
  animation-duration: 0.8s;
  transition: 0.3s;
}
.admin_link i {
  color: white;
  position: absolute;
  top: -20px;
  left: -20px;
}

.admin_link:hover {
  top: 0px;
  left: 0px;
}

@keyframes admin_link_start {
  from {
    top: 0px;
    left: 0px;
  }
  to {
    top: -36px;
    left: -36px;
  }
}
.full_site .site_content, .full_site footer, .bouton_reservation {
  transition: transform 0.5s;
}

.full_site.navbar_opened .site_content, .full_site.navbar_opened footer, .full_site.navbar_opened .bouton_reservation {
  transform: translateX(-30vw);
}

.choix_langue {
  position: fixed;
  z-index: 100;
  display: block !important;
  top: 60px;
  right: 16px;
}
.choix_langue .choose {
  font-size: 0.7rem;
}
.choix_langue p {
  margin: 0;
}
.choix_langue a {
  color: #000000;
}
.choix_langue a:hover {
  text-decoration: underline;
}
.choix_langue a.active {
  text-decoration: underline;
  font-weight: 700;
}

.filets {
  position: relative;
  color: #000000;
}
.filets .filet_vertical {
  position: relative;
  display: inline-block;
  height: 100%;
  background-color: #000000;
}
.filets .filet_vertical .mot {
  z-index: 10;
  font-family: "questa-grande";
  position: absolute;
  font-size: 1.6rem;
  white-space: nowrap;
}
.filets .filet_vertical.ep1 {
  width: 1px;
}
.filets .filet_vertical.ep2 {
  width: 4px;
}

.shadow {
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3);
}

.paragraphe_gros {
  font-size: 1.5rem;
  font-family: "questa-grande";
}

p {
  color: #000000;
  font-size: 0.95rem;
  font-weight: 300;
}

.entete_page {
  height: 50vh;
  text-align: center;
}
.entete_page h1 {
  color: white;
  font-size: 4rem;
}
.entete_page h1 span {
  font-family: "questa-grande";
}
.entete_page h1 .part1 {
  font-style: italic;
}
.entete_page h1 .part2 {
  font-weight: 700;
}
.entete_page h3 {
  color: #ffffff;
}

.restaurant .entete_page {
  background: url(../images/restaurant_entete.jpg) no-repeat center center;
  background-size: cover;
}

.reservation .entete_page {
  background: url(../images/reservation_entete.jpg) no-repeat center center;
  background-size: cover;
}

.chef_equipe .entete_page {
  background: url(../images/chef_equipe_entete.jpg) no-repeat center center;
  background-size: cover;
}

.carte_menu .entete_page {
  background: url(../images/carte_menu_entete.jpg) no-repeat center center;
  background-size: cover;
}

.ils_parlent_de_nous .entete_page {
  background: url(../images/ils_parlent_de_nous_entete.jpg) no-repeat center center;
  background-size: cover;
}

.village_yvoire .entete_page {
  background: url(../images/village_entete.jpg) no-repeat center center;
  background-size: cover;
}

.actualites .entete_page {
  background: url(../images/actualites_entete.jpg) no-repeat center center;
  background-size: cover;
}

.contact_acces .entete_page {
  background: url(../images/contact_acces_entete_2023.jpg) no-repeat center center;
  background-size: cover;
}

.titre_type_1 {
  text-align: right;
  color: #000000;
}
.titre_type_1 .part1 {
  font-size: 1.3rem;
  font-weight: 500;
}
.titre_type_1 .part2 {
  font-family: "questa-grande";
  font-size: 3rem;
  line-height: 2rem;
  font-weight: 700;
}

.bouton_type_1 {
  color: #000000;
  text-transform: uppercase;
  text-decoration: none;
  padding-bottom: 4px;
  border-bottom: 2px solid #000000;
}

.bouton_type_1:hover {
  color: #000000;
  text-decoration: none;
}

@media (max-width: 1199px) {
  .filets {
    display: none;
  }
}
.boutons_nav {
  position: fixed;
  display: flex;
  z-index: 500;
  top: 0;
  right: 20vw;
}
.boutons_nav .bouton_reservation, .boutons_nav .bouton_cadeau {
  text-align: center;
}
.boutons_nav .bouton_reservation {
  margin-left: 14px;
}
.boutons_nav .bouton_reservation .lien_cadeau {
  border-top: 1px solid #ffffff;
}
.boutons_nav a {
  display: block;
  padding: 0.3rem 3rem;
  background-color: #000000;
  color: #ffffff;
}
.boutons_nav .filet {
  margin-top: 14px;
  margin-left: 10%;
  width: 80%;
  height: 3px;
  background-color: #ffffff;
}
.boutons_nav .cadeau_desk {
  display: block;
}
.boutons_nav .cadeau_resp {
  display: none;
}
@media (max-width: 575px) {
  .boutons_nav {
    right: auto;
    left: 20px;
  }
  .boutons_nav .bouton_reservation {
    margin-left: 0;
  }
  .boutons_nav .cadeau_desk {
    display: none;
  }
  .boutons_nav .cadeau_resp {
    display: block;
  }
}

.navbar .contact {
  margin-top: 60px;
}
.navbar .contact p {
  margin: 0;
  font-size: 0.8rem;
  white-space: nowrap;
}
.navbar .contact h5 {
  color: #000000;
  margin-top: 20px;
  white-space: nowrap;
  font-weight: 700;
}
.navbar .contact .filet {
  height: 1px;
  width: 50%;
  background-color: #000000;
}
.navbar .langue .choose {
  font-size: 0.7rem;
}
.navbar .langue p {
  margin: 0;
}
.navbar .langue a {
  color: #000000;
}
.navbar .langue a:hover {
  text-decoration: underline;
}
.navbar .langue a.active {
  text-decoration: underline;
  font-weight: 700;
}
.navbar .reseaux {
  margin-top: 60px;
}
.navbar .reseaux p {
  margin: 0;
}
.navbar .reseaux a {
  color: #000000;
  font-size: 1.4rem;
  line-height: 1.4rem;
}

.navbar {
  z-index: 1000;
  width: 100%;
  background-color: #dbdbdb;
  box-shadow: 0px 0px 0 rgba(0, 0, 0, 0.3);
}
.navbar .container {
  max-width: none !important;
  padding: 0;
}
.navbar .navbar_collapsed_header {
  display: none;
}

.navbar-brand {
  height: 40px;
}
.navbar-brand img {
  height: 100%;
  width: auto;
}

.nav-item {
  position: relative;
  margin: 0px 0px;
  white-space: nowrap;
}
.nav-item .nav-link {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.9rem;
  font-weight: 500;
  color: #000000;
  background-color: transparent;
  border-radius: 0px;
  padding: 0.5rem 0rem !important;
  transition: 0s;
  text-transform: uppercase;
  z-index: 10000 !important;
}
.nav-item .nav-underline {
  position: absolute;
  bottom: 0;
  background-color: #000000;
  transition: 0.25s;
  height: 4px;
  width: 0;
  left: 0;
}

.nav-item:hover .nav-link, .nav-item:active .nav-link, .nav-item.active .nav-link, .nav-item.show .nav-link {
  color: black;
  background-color: transparent;
  border-radius: 0px;
}
.nav-item:hover .nav-underline, .nav-item:active .nav-underline, .nav-item.active .nav-underline, .nav-item.show .nav-underline {
  width: 50%;
}

.nav-item.icon {
  margin: 0 10px !important;
}
.nav-item.icon .nav-link {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.nav-item.icon .nav-link:hover {
  background-color: transparent !important;
}
.nav-item.icon .nav_icon_container {
  border: 1px solid black;
  border-radius: 1000px;
  padding: 16px;
  background-color: transparent;
  height: 20px;
  width: 20px;
  transition: 0.2s;
}
.nav-item.icon .nav_icon_container i {
  color: #000000;
  transition: 0s;
  font-size: 1.1rem;
}

.nav-item:hover .nav_icon_container {
  border: 1px solid black;
  border-radius: 1000px;
  background-color: rgba(255, 0, 0, 0.3);
}
.nav-item:hover i {
  color: black;
}

.nav-item .dropdown-menu {
  padding: 0 0;
  background-color: #909090;
  border-radius: 0 !important;
  border: 0 solid transparent;
  box-shadow: none !important;
  top: 100%;
  top: 0;
  left: calc(-0.5rem - 0 + 0rem);
  transition: 0.5s;
  display: block !important;
  opacity: 0;
  pointer-events: none;
  transform: translateY(50px);
  padding-top: 60px;
}
.nav-item .dropdown-menu a {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 0.9rem;
  padding: 1rem 0.5rem;
  text-align: left;
}
.nav-item .dropdown-menu a:hover {
  background-color: #505050;
}

.nav-item .dropdown-menu.show {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(-10px);
}

/* $navbar_toggler_ratio: $navbar_togglericon_height / $navbar_togglericon_width;
@if $navbar_toggler_ratio < 0.5 { $togglerbar_angle: 25deg; } */
.navbar-toggler {
  z-index: 100;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  border: 0px solid transparent;
  border-radius: 0px;
}
.navbar-toggler .navbar-toggler-icon {
  position: relative;
  width: 30px;
}
.navbar-toggler .navbar-toggler-icon .toggler-bar {
  position: absolute;
  width: 100%;
  left: 0;
  height: 4px;
  background-color: #000000;
  transform-origin: center center;
  transform: translateY(-50%);
  transition: 0.2s;
}
.navbar-toggler .navbar-toggler-icon .top-bar {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.navbar-toggler .navbar-toggler-icon .middle-bar {
  top: 50%;
  opacity: 0;
  transition: 0.2s;
}
.navbar-toggler .navbar-toggler-icon .bottom-bar {
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

.navbar-toggler.collapsed {
  transform: translateY(0%);
}
.navbar-toggler.collapsed .navbar-toggler-icon .top-bar {
  top: 0;
  transform: translateY(0%) rotate(0deg);
}
.navbar-toggler.collapsed .navbar-toggler-icon .middle-bar {
  opacity: 1;
  transition: 0.2s;
}
.navbar-toggler.collapsed .navbar-toggler-icon .bottom-bar {
  top: 100%;
  transform: translateY(-100%) rotate(0deg);
}

@media (max-width: 1199px) {
  .nav-underline {
    display: none;
  }
}
.navbar {
  padding: 0;
  /* .nav-item:first-child {
  	margin: $navbar_links_margin;
  }
  .nav-item:last-child {
  	margin: $navbar_links_margin;
  } */
}
.navbar .navbar-collapse {
  width: 100% !important;
}
.navbar .navbar-brand {
  display: none;
}
.navbar .puller {
  display: none;
}
.navbar .navbar-collapse {
  left: auto;
  right: 0;
}
.navbar .navbar-collapse {
  background-color: #dbdbdb;
  height: 100%;
  width: 30vw !important;
}
.navbar .navbar-collapse .container {
  display: flex;
  padding: 60px 5% 10% 10%;
  height: 100%;
  width: 100%;
  /* @if $navbar_collapse_content_horizontal_align == left { justify-content: flex-start; }
  @if $navbar_collapse_content_horizontal_align == center { justify-content: center; }
  @if $navbar_collapse_content_horizontal_align == right { justify-content: flex-end; } */
  justify-content: space-between;
}
.navbar .navbar-collapse .container .col_centrale {
  flex: 10;
}
.navbar .navbar-collapse .container .col {
  padding: 0;
  flex-grow: 0;
  align-self: flex-start;
}
.navbar .navbar-collapse .container .col .etage {
  padding: 0;
  width: 100%;
  flex-grow: initial;
}
.navbar .navbar-collapse .container .col .etage img {
  width: 100%;
  height: auto;
}
.navbar .navbar-collapse .container .navbar-nav {
  display: inline-block;
  /* @if $navbar_collapse_vertical_align == top { align-self: flex-start; }
  @if $navbar_collapse_vertical_align == bottom { align-self: flex-end; } */
  text-align: left;
}
.navbar .navbar-collapse .container .navbar-nav a {
  font-size: 1.1rem !important;
  font-weight: 500;
}
.navbar .navbar-collapse .container .navbar-nav .nav-item.icon {
  display: inline-block;
}
.navbar .navbar-collapse .container .navbar-nav .nav-link {
  transform: none;
}
.navbar {
  padding: 0;
  background-color: transparent;
}

.navbar-toggler {
  position: fixed;
  top: 16px;
  right: 16px;
}

.navbar-collapse {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}

.nav-item .dropdown-menu {
  position: relative;
  transition: none;
  display: block;
  opacity: 1;
  transform: none;
  padding-top: 0;
  background: none;
  padding-left: 30px;
}

@media (max-width: 991px) {
  .navbar .navbar-collapse {
    width: 100% !important;
  }
}
#cookie_notice {
  position: fixed;
  bottom: 0;
  overflow: hidden;
  z-index: 1000;
  background-color: #493e47;
  max-height: 200px;
  opacity: 1;
  transition: 0.3s;
}
#cookie_notice * {
  margin: 0;
  font-size: 0.9rem;
}
#cookie_notice .flex_container {
  display: flex;
  align-items: flex-end;
  padding: 10px 20px;
}
#cookie_notice .texte, #cookie_notice .texte p {
  flex: 1;
  color: #ffffff;
}
#cookie_notice .lien {
  flex: initial;
  padding: 0 15px;
}
#cookie_notice .lien a {
  color: #ffffff;
  text-decoration: underline;
}
#cookie_notice .bouton {
  flex: initial;
}
#cookie_notice .bouton button {
  cursor: pointer;
  color: #ffffff;
  background-color: #000000;
  transition: 0.1s;
}
#cookie_notice .bouton button:hover {
  background-color: #101010;
}

#cookie_notice.hide {
  max-height: 0;
}

.cookies_text {
  padding: 4vw;
}
.cookies_text p {
  margin-bottom: 20px;
}
.cookies_text h6 {
  margin-top: 30px;
}
.cookies_text h6:first-child {
  margin-top: 0px;
}
.cookies_text table td {
  padding-right: 20px;
}

@media (max-width: 1199px) {
  #cookie_notice {
    max-height: 400px;
  }
  #cookie_notice .flex_container {
    flex-direction: column;
    align-items: flex-start;
  }
  #cookie_notice .flex_container > div {
    margin-bottom: 16px;
  }
  #cookie_notice .lien {
    padding: 0;
  }
}
footer .mentions {
  color: #ffffff;
  font-size: 0.7rem;
  margin: 0;
}
footer .contact_col {
  text-align: center;
}
footer .contact_col .centered {
  display: block;
  width: auto;
  text-align: left;
  padding-left: 30px;
}
footer .contact_col p {
  color: #ffffff;
  margin: 0;
  line-height: 1rem;
}
footer .contact_col h5 {
  margin: 0;
  margin-top: 30px;
  font-weight: 700;
}
footer .contact_col .reseaux {
  margin-top: 30px;
  display: flex;
  justify-content: self-end;
}
footer .contact_col .reseaux .filet {
  width: 140px;
  margin-top: 15px;
  height: 1px;
  background-color: #ffffff;
}
footer .contact_col .reseaux i {
  margin-left: 10px;
}
footer #newsletter_container h6 {
  font-size: 0.8rem;
  font-weight: 700;
}
footer #newsletter_container .champs_newsletter {
  width: 100%;
  border: none;
}
footer #newsletter_container #newsletter_submit_btn {
  cursor: pointer;
  border: none;
  margin-top: 5px;
  float: right;
  background: transparent;
  color: white;
  font-weight: 700;
  border-bottom: 2px solid #ffffff;
  transition: border 0.2s;
}
footer #newsletter_container #newsletter_submit_btn:hover {
  border-bottom: 5px solid #ffffff;
}

@media (max-width: 1199px) {
  .centered {
    padding-left: 0 !important;
  }
}
footer {
  background-color: #493e47;
  padding: 8vw 3vw 3vw 3vw;
  color: #ffffff;
}
footer .separateur {
  width: 20px;
}
footer a {
  color: #ffffff;
  text-decoration: underline;
}
footer a:hover {
  color: #ffffff;
}

@media (max-width: 1199px) {
  footer {
    flex-direction: column-reverse;
  }
  footer .separateur {
    width: 100%;
    height: 30px;
  }
}
#carousel_accueil {
  position: relative;
  height: 100%;
  width: 70%;
}
#carousel_accueil .content_over_carousel {
  position: absolute;
  z-index: 10;
}
#carousel_accueil .carousel-inner {
  position: relative;
  height: 100%;
  width: 100%;
}
#carousel_accueil .carousel-inner .carousel-item {
  height: 100%;
  width: 100%;
}
#carousel_accueil .carousel-inner .carousel-item img {
  position: absolute;
  z-index: -100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#carousel_accueil .carousel-control {
  width: 10%;
}
#carousel_accueil .carousel-control i {
  font-size: 1rem !important;
}
#carousel_accueil .carousel-indicators {
  transition: 0.2s;
  cursor: pointer;
  margin: 0;
  bottom: 20px;
}
#carousel_accueil .carousel-indicators li {
  transition: 0.2s;
  height: 3px;
  width: 20px;
  margin: 0 3px;
  background-color: rgba(0, 0, 0, 0.4);
  border: none;
  border-radius: 0;
}
#carousel_accueil .carousel-indicators li .carousel-indicator-inside {
  transition: 0.2s;
  border-radius: 0;
  height: 0px;
  width: 0px;
  background-color: rgba(0, 0, 0, 0);
}
#carousel_accueil .carousel-indicators li.active {
  background-color: black;
  border: none;
}
#carousel_accueil .carousel-indicators li.active else {
  border: keep;
}
#carousel_accueil .carousel-indicators li.active .carousel-indicator-inside {
  height: 0px;
  width: 0px;
  background-color: black;
}
#carousel_accueil .carousel-indicators {
  transform: rotate(90deg);
  right: -80px;
  left: auto;
}

#carousel_galerie_contact {
  position: relative;
  height: 100%;
  width: 100%;
}
#carousel_galerie_contact .content_over_carousel {
  position: absolute;
  z-index: 10;
}
#carousel_galerie_contact .carousel-inner {
  position: relative;
  height: 100%;
  width: 100%;
}
#carousel_galerie_contact .carousel-inner .carousel-item {
  height: 100%;
  width: 100%;
}
#carousel_galerie_contact .carousel-inner .carousel-item img {
  position: absolute;
  z-index: -100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#carousel_galerie_contact .carousel-control {
  width: 10%;
  opacity: 0.6;
}
#carousel_galerie_contact .carousel-control i {
  padding: 6px 10px;
  font-size: 1.5rem !important;
  border-radius: 100px;
  color: #000000;
  background-color: #ffffff;
}
#carousel_galerie_contact .carousel-indicators {
  transition: 0.2s;
  cursor: pointer;
  margin: 0;
  bottom: 20px;
}
#carousel_galerie_contact .carousel-indicators li {
  transition: 0.2s;
  height: 3px;
  width: 20px;
  margin: 0 3px;
  background-color: rgba(255, 255, 255, 0.4);
  border: none;
  border-radius: 0;
}
#carousel_galerie_contact .carousel-indicators li .carousel-indicator-inside {
  transition: 0.2s;
  border-radius: 0;
  height: 0px;
  width: 0px;
  background-color: rgba(255, 255, 255, 0);
}
#carousel_galerie_contact .carousel-indicators li.active {
  background-color: white;
  border: none;
}
#carousel_galerie_contact .carousel-indicators li.active else {
  border: keep;
}
#carousel_galerie_contact .carousel-indicators li.active .carousel-indicator-inside {
  height: 0px;
  width: 0px;
  background-color: white;
}

#carousel_galerie_accueil {
  position: relative;
  height: 100%;
  width: 100%;
}
#carousel_galerie_accueil .content_over_carousel {
  position: absolute;
  z-index: 10;
}
#carousel_galerie_accueil .carousel-inner {
  position: relative;
  height: 100%;
  width: 100%;
}
#carousel_galerie_accueil .carousel-inner .carousel-item {
  height: 100%;
  width: 100%;
}
#carousel_galerie_accueil .carousel-inner .carousel-item img {
  position: absolute;
  z-index: -100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#carousel_galerie_accueil .carousel-control {
  width: 10%;
  opacity: 0.6;
}
#carousel_galerie_accueil .carousel-control i {
  padding: 6px 10px;
  font-size: 1.5rem !important;
  border-radius: 100px;
  color: #000000;
  background-color: #ffffff;
}
#carousel_galerie_accueil .carousel-indicators {
  transition: 0.2s;
  cursor: pointer;
  margin: 0;
  bottom: 20px;
}
#carousel_galerie_accueil .carousel-indicators li {
  transition: 0.2s;
  height: 3px;
  width: 20px;
  margin: 0 3px;
  background-color: rgba(255, 255, 255, 0.4);
  border: none;
  border-radius: 0;
}
#carousel_galerie_accueil .carousel-indicators li .carousel-indicator-inside {
  transition: 0.2s;
  border-radius: 0;
  height: 0px;
  width: 0px;
  background-color: rgba(255, 255, 255, 0);
}
#carousel_galerie_accueil .carousel-indicators li.active {
  background-color: white;
  border: none;
}
#carousel_galerie_accueil .carousel-indicators li.active else {
  border: keep;
}
#carousel_galerie_accueil .carousel-indicators li.active .carousel-indicator-inside {
  height: 0px;
  width: 0px;
  background-color: white;
}

.modal-body .carousel {
  position: relative;
  height: 100%;
  width: 100%;
}
.modal-body .carousel .carousel-inner {
  position: relative;
  height: 100%;
  width: 100%;
}
.modal-body .carousel .carousel-inner .carousel-item {
  height: 100%;
  width: 100%;
}
.modal-body .carousel .carousel-inner .carousel-item img {
  position: absolute;
  z-index: -100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.modal-body .carousel .carousel-indicators {
  cursor: pointer;
}

form label {
  font-size: 1rem;
  font-weight: 500;
  font-style: none;
  margin-bottom: 0px;
  padding-left: 0px;
}
form .form-check-label {
  font-size: 1rem;
  font-weight: 500;
  font-style: none;
  margin-bottom: 0px;
  padding-left: 1.25rem;
}
form .form-control {
  border: none;
  border-radius: 0;
  color: #000000;
  background-color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  font-style: none;
  margin-bottom: 0px;
}
form .form-control::placeholder {
  color: rgba(0, 0, 0, 0.3);
}
form .form-control:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
form .form-control::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.modal {
  padding-right: 0px !important;
}

.modal-dialog.centered {
  margin: 0 !important;
  transform: translate(-50%, -50%) !important;
}

.modal.bare .modal-dialog .modal-content {
  border-radius: 0 !important;
  border: none;
}
.modal.bare .modal-dialog .modal-content button.close {
  cursor: pointer;
  position: absolute;
  z-index: 10;
  top: 10px;
  right: 10px;
  font-size: 3rem;
  line-height: 0.5em;
  color: #000000;
}
.modal.bare .modal-dialog .modal-content .modal-body {
  padding: 0;
}

.carousel_modal .modal-dialog {
  width: 80vw !important;
  height: 80vh !important;
  max-width: none;
}
.carousel_modal .modal-dialog .modal-content {
  height: 100%;
}
.carousel_modal .carousel {
  height: 80vh !important;
}

.video_modal .modal-dialog {
  width: 70vw !important;
  height: 39.375vw !important;
  max-width: none;
}
.video_modal .modal-dialog .modal-content {
  height: 100%;
}
.video_modal .modal-dialog .modal-content video {
  width: 100%;
}
.video_modal .modal-dialog .modal-content iframe {
  width: 100%;
  height: 100%;
}
.video_modal .modal-dialog .modal-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video_modal .carousel {
  height: 100%;
}

.form_error_box .modal-title {
  font-weight: 700;
}
.form_error_box .form_modal_ok {
  color: #008000;
}
.form_error_box .form_modal_fail {
  color: #a00000;
}
.form_error_box i {
  float: left;
  margin-right: 16px;
  font-size: 3rem;
  margin-bottom: 16px;
}
.form_error_box h6 {
  margin-top: 26px;
  font-weight: 700;
}
.form_error_box ul {
  padding: 0;
  list-style: none;
}
.form_error_box ul li {
  border-top: 1px solid #e0e0e0;
  font-size: 0.9rem;
  padding-top: 8px;
  margin-bottom: 10px;
}

#modal_event .modal-dialog, #modal_event .modal-content {
  height: auto;
  width: 100%;
  max-width: 500px;
}
#modal_event .modal-content {
  position: relative;
  padding: 30px;
  min-height: 100px;
  text-align: center;
}
#modal_event .modal-content .close span {
  color: #000000 !important;
}
#modal_event .modal-content h3 {
  color: #000000;
  text-align: center;
  font-size: 1rem;
  font-weight: 300;
  margin: 0;
}
#modal_event .modal-content .filet {
  display: inline-block;
  height: 2px;
  background-color: #000000;
  width: 100px;
  margin: 14px 0;
}
#modal_event .modal-content p {
  margin: 0;
}
#modal_event .modal-content h2 {
  margin-bottom: 30px;
}
#modal_event .modal-content h5 {
  margin-bottom: 30px;
  font-size: 1.5rem;
}
#modal_event .modal-content img {
  display: block;
  width: auto;
  margin: auto;
  margin-bottom: 30px;
}
#modal_event .modal-content .grey {
  color: #1a1a1a;
}
#modal_event .modal-content .bouton {
  cursor: pointer;
  text-transform: none;
  border: none;
  background-color: transparent;
  border-bottom: 2px solid #000000;
}
#modal_event .modal-content .content_container {
  position: relative;
  margin-top: 10px;
  margin-bottom: 0px;
}
#modal_event .modal-content .content_container .filet {
  height: 1px;
}

#modal_galerie_reservation #confirmation_reservation_ok, #modal_galerie_reservation #confirmation_reservation_fail {
  display: none;
}
#modal_galerie_reservation .modal-dialog, #modal_galerie_reservation .modal-content {
  height: auto;
  width: 100%;
  max-width: 500px;
  top: 50%;
  transform: translateY(-50%);
}
#modal_galerie_reservation .modal-content {
  position: relative;
  padding: 30px;
  min-height: 100px;
}
#modal_galerie_reservation .modal-content iframe {
  min-height: 85vh;
}
#modal_galerie_reservation .modal-content .close span {
  color: #000000 !important;
}
#modal_galerie_reservation .modal-content h1 {
  text-align: center;
  color: #000000;
}
#modal_galerie_reservation .modal-content h1 span {
  font-family: "questa-grande";
}
#modal_galerie_reservation .modal-content h1 .part1 {
  font-style: italic;
}
#modal_galerie_reservation .modal-content h1 .part2 {
  font-weight: 700;
}
#modal_galerie_reservation .modal-content h3 {
  display: inline-block;
  color: #000000;
  text-align: center;
  margin-left: 50%;
  transform: translateX(-50%);
  font-size: 1rem;
  font-weight: 700;
  border-bottom: 2px solid #000000;
}
#modal_galerie_reservation .modal-content .legals {
  position: absolute;
  left: 0;
  bottom: -56px;
  color: #000000;
  font-size: 0.8rem;
  text-decoration: underline;
}
#modal_galerie_reservation .modal-content .bouton {
  cursor: pointer;
  text-transform: none;
  border: none;
  border-bottom: 2px solid #000000;
}
#modal_galerie_reservation .modal-content .content_container {
  position: relative;
  margin-top: 30px;
  margin-bottom: 0px;
}
#modal_galerie_reservation .modal-content .content_container .content {
  pointer-events: all;
  display: block;
  opacity: 1;
  transition: opacity 0.5s;
}
#modal_galerie_reservation .modal-content .content_container .content.hidden {
  display: none;
  opacity: 0;
  pointer-events: none;
}
#modal_galerie_reservation .modal-content .content_container label {
  font-size: 0.9rem;
  margin-bottom: 10px;
  font-style: italic;
}
#modal_galerie_reservation .modal-content .content_container .form select {
  width: 100%;
  border: 1px solid #000000;
  border-radius: 0;
  color: #000000;
}
#modal_galerie_reservation .modal-content .content_container .form .infos_perso input {
  background-color: #dbdbdb;
  margin-bottom: 10px;
}
#modal_galerie_reservation .modal-content .content_container .form .bouton {
  cursor: pointer;
  background: transparent;
  padding-bottom: 4px;
  border: none;
  border-bottom: 2px solid #000000;
}
#modal_galerie_reservation .modal-content .content_container .form .form-check {
  margin-bottom: 0;
}
#modal_galerie_reservation .modal-content .content_container .form .rappels {
  margin-bottom: 30px;
}
#modal_galerie_reservation .modal-content .content_container .form .rappels p {
  margin-bottom: 0;
  font-weight: 700;
}
#modal_galerie_reservation .modal-content .content_container .form .rappels p span {
  font-weight: 500;
  color: green;
}
#modal_galerie_reservation .modal-content .content_container .form .rappels p .red {
  color: red;
}
#modal_galerie_reservation .modal-content .content_container .form .check_full_alert {
  color: #e00000;
}
#modal_galerie_reservation .modal-content .content_container .form .submit:disabled {
  cursor: initial;
  color: #999999;
  border-bottom: 2px solid #999999;
}
#modal_galerie_reservation .modal-content #datepicker {
  width: 100%;
  margin-bottom: 10px;
}
#modal_galerie_reservation .modal-content #datepicker .ui-datepicker-header {
  position: relative;
  padding: 10px;
  border: 1px solid #000000;
}
#modal_galerie_reservation .modal-content #datepicker .ui-datepicker-header .ui-datepicker-prev {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  font-size: 1.4rem;
  font-weight: 700;
}
#modal_galerie_reservation .modal-content #datepicker .ui-datepicker-header .ui-datepicker-next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  font-size: 1.4rem;
  font-weight: 700;
}
#modal_galerie_reservation .modal-content #datepicker .ui-datepicker-header .ui-datepicker-title {
  text-align: center;
  font-weight: 700;
}
#modal_galerie_reservation .modal-content #datepicker .ui-datepicker-calendar {
  width: 100%;
  text-align: center;
}
#modal_galerie_reservation .modal-content #datepicker .ui-datepicker-calendar thead {
  background-color: #000000;
  color: #ffffff;
}
#modal_galerie_reservation .modal-content #datepicker .ui-datepicker-calendar thead th {
  padding: 4px;
}
#modal_galerie_reservation .modal-content #datepicker .ui-datepicker-calendar td {
  position: relative;
  box-sizing: border-box;
  background-clip: padding-box;
  user-select: none;
  height: 30px;
  width: 14.285714%;
  padding: 4px;
  border: 1px solid #000000;
  font-size: 0.85rem;
}
#modal_galerie_reservation .modal-content #datepicker .ui-datepicker-calendar td a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #000000;
  line-height: 30px;
}
#modal_galerie_reservation .modal-content #datepicker .ui-datepicker-calendar td a:hover {
  text-decoration: none;
}
#modal_galerie_reservation .modal-content #datepicker .ui-datepicker-calendar .ui-state-active {
  background-color: #f1c621;
}
#modal_galerie_reservation .modal-content #datepicker .ui-datepicker-calendar .ui-datepicker-unselectable {
  pointer-events: none;
  background-color: #aaaaaa;
  border: 1px solid black;
}

.index #section1 {
  height: 60vh;
}
.index #section1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.index #section1 h1 {
  position: absolute;
  z-index: 100;
  top: 30vh;
  left: 7vw;
  transform: translateY(-50%);
  color: white;
  font-size: 4rem;
}
.index #section1 h1 span {
  font-family: "questa-grande";
}
.index #section1 h1 .part1 {
  font-style: italic;
}
.index #section1 h1 .part2 {
  font-weight: 700;
}
.index #section1 .reseaux {
  position: absolute;
  right: 40px;
  bottom: 40px;
}
.index #section1 .reseaux p {
  margin: 0;
  font-size: 1.4rem;
}
.index #section1 .reseaux a:hover {
  color: #ffffff;
}
.index #section2 {
  background-color: #dbdbdb;
}
.index #section2 .col1 {
  padding: 5vw;
  text-align: center;
}
.index #section2 .col1 p {
  margin: 5px;
  font-size: 1.3rem;
}
.index #section2 .col1 h1 {
  color: white;
  opacity: 0;
  font-size: 3.5rem;
  transition: 0.8s;
}
.index #section2 .col1 h1 span {
  font-family: "questa-grande";
}
.index #section2 .col1 h1 .part1 {
  font-style: italic;
}
.index #section2 .col1 h1 .part2 {
  font-weight: 700;
}
.index #section2 .col1 h1:hover {
  opacity: 1;
}
.index #section2 .col1 h5 {
  font-weight: 700;
  margin-top: 30px;
}
.index #section2 .col1 .yvoire {
  margin-top: 20px;
  font-size: 0.8rem;
  font-weight: 700;
}
.index #section2 .col1 .filet {
  margin: auto;
  height: 2px;
  width: 46px;
  background-color: #000000;
}
.index #section2 .col2 {
  flex-grow: 0.3;
  padding: 30px 0 !important;
}
.index #section2 .col2 .filet_vertical._1 {
  top: -50px;
  height: 70%;
}
.index #section2 .col2 .filet_vertical._1 .mot {
  bottom: 0px;
  right: 30px;
  transform-origin: 100% 50%;
  transform: rotate(-90deg);
}
.index #section2 .col2 .filet_vertical._2 {
  top: -200px;
}
.index #section2 .col2 .filet_vertical._2 .mot {
  top: -60px;
  left: 30px;
  transform-origin: 0 50%;
  transform: rotate(-90deg) translateX(-100%);
  color: white;
}
.index #section2 .col3 {
  flex-grow: 6;
}
.index #section2 .col3 .nav_accueil {
  padding: 16px 30px;
  justify-content: space-between;
}
.index #section2 .col3 .nav_accueil a {
  font-size: 0.9rem;
  font-weight: 300;
  text-decoration: none;
  text-transform: uppercase;
  border-bottom: none;
  border-bottom: 3px solid transparent;
  transition: 0.1s;
}
.index #section2 .col3 .nav_accueil a:hover {
  color: #000000;
  border-bottom: 3px solid #000000;
}
.index #section2 .col3 .carousel {
  transform: translateY(20px);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3);
}
.index #section3 {
  z-index: -1;
  padding-top: 10vw;
  padding-bottom: 3vw;
}
.index #section3 div > div {
  margin-left: 6px;
}
.index #section3 div > div img {
  width: 100%;
}
.index #section3 .img1 {
  transform: translateY(50%);
}
.index #section4 {
  padding: 0 5vw;
}
.index #section4 .container {
  flex-wrap: nowrap !important;
}
.index #section4 .col {
  flex: 1;
}
.index #section4 .filets {
  flex: 0.04;
}
.index #section4 .filets .filet_vertical._1 {
  bottom: -30%;
  height: 50%;
}
.index #section4 .filets .filet_vertical._1 .mot {
  bottom: -100px;
  left: 20px;
  transform-origin: 0% 50%;
  transform: rotate(-90deg);
}
.index #section4 .image_decoupe_container {
  margin-top: 100px;
}
.index #section4 .image_decoupe_container .bande {
  overflow: hidden;
  height: auto;
  width: 20%;
}
.index #section4 .image_decoupe_container .bande img {
  position: relative;
  width: 500%;
}
.index #section4 .image_decoupe_container .bande._1 img {
  left: 0%;
}
.index #section4 .image_decoupe_container .bande._2 img {
  left: -100%;
}
.index #section4 .image_decoupe_container .bande._3 img {
  left: -200%;
}
.index #section4 .image_decoupe_container .bande._4 img {
  left: -300%;
}
.index #section4 .image_decoupe_container .bande._5 img {
  left: -400%;
}
.index #section4 .col.texte h2 {
  color: #000000;
  text-align: right;
}
.index #section4 .col.texte h2 .part1 {
  font-size: 1.3rem;
}
.index #section4 .col.texte h2 .part2 {
  font-family: "questa-grande";
  font-size: 3rem;
  font-weight: 700;
}
.index #section5 {
  height: 100vh;
  margin-top: 60px;
}
.index #section5 h2 {
  position: absolute;
  z-index: 12;
  bottom: 20px;
  left: 60vw;
  color: #ffffff;
  text-align: right;
}
.index #section5 h2 .part1 {
  font-size: 1.3rem;
}
.index #section5 h2 .part2 {
  font-family: "questa-grande";
  font-size: 3rem;
  font-weight: 700;
}
.index #section5 .img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.index #section5 .img.noirblanc {
  z-index: 10;
  background: url(../images/accueil_img_changement_noirblanc_2023.jpg) no-repeat center left;
  background-size: 100vw;
}
.index #section5 .img.couleur {
  z-index: 11;
  background: url(../images/accueil_img_changement_couleur_2023.jpg) no-repeat center left;
  background-size: 100vw;
  width: 0%;
  /* border-right: 2px solid $color_white; */
}
.index #section5 .transition {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 30px;
  /* background: linear-gradient(to right, transparent, rgba($color_white, 0.5)); */
  border-right: 2px solid #ffffff;
}
.index #section6 {
  z-index: 100;
  margin-top: 20px;
}
.index #section6 .images img {
  width: 100%;
}
.index #section6 .images .img2 {
  position: absolute;
  left: 0;
  top: 10px;
  transform: translateY(100%);
}
.index #section6 .images .img3 {
  position: absolute;
  top: 10px;
  left: 10px;
  transform: translate(100%, 100%);
}
.index #section6 .texte .filets {
  z-index: 10000;
  flex-basis: 30px;
  flex-grow: 0;
  padding: 0 10px;
}
.index #section6 .texte .filets .filet_vertical._1 {
  top: -60%;
  height: 160%;
}
.index #section6 .texte .filets .filet_vertical._1 .mot {
  color: #ffffff;
  top: 0px;
  right: 20px;
  transform-origin: 100% 50%;
  transform: rotate(-90deg);
}
.index #section6 .texte .texte {
  padding-left: 30px;
  padding-top: 30px;
  padding-right: 10%;
}
.index #section7 {
  margin-top: 160px;
  color: #000000;
}
.index #section7 .image {
  padding: 30px 0;
}
.index #section7 .image img {
  width: 100%;
  transform: translateX(15%);
}
.index #section7 .titre {
  text-align: right;
  border: 1px solid #000000;
  padding: 40px;
  padding-left: 150px;
}
.index #section7 .titre p {
  margin-top: 20px;
  margin-bottom: 0;
}
.index #section8 {
  margin-top: 70px;
  margin-bottom: 50px;
}
.index #section8 .col-4 img {
  width: 95%;
  transform: translateY(50%);
}
.index #section8 .titrage {
  justify-content: space-between;
}
.index #section8 .titrage h2 {
  align-self: flex-end;
}
.index #section8 .titrage img {
  width: auto;
}
.index #section8 .texte {
  margin-top: 20px;
  padding-left: 5%;
}
.index #section10 {
  margin-top: 150px;
}
.index #section10 .cadre {
  position: absolute;
  left: 10vw;
  bottom: 0;
  z-index: 10;
  padding: 20vw;
  border: 2px solid #000000;
  transform: translateY(30%);
}
.index #section10 .cadre img {
  position: absolute;
  width: 40%;
  top: 0;
  right: 5%;
}
.index #section10 .cadre .img1 {
  transform: translateY(-80%);
}
.index #section10 .cadre .img2 {
  transform: translateY(-185%);
}
.index #section10 .cadre h2 {
  position: absolute;
  right: -120px;
  top: 20%;
  color: #ffffff;
  line-height: 3rem;
}
.index #section10 .cadre h2 .part2 {
  font-size: 4.5rem;
}
.index #section10 .cadre h2 .part1 {
  font-size: 1rem;
  margin-right: 200px;
}
.index #section11 {
  margin: 80px 0;
}
.index #section11 .zone_photos {
  position: relative;
  margin-left: 20%;
  width: 50%;
  height: 32vw;
}
.index #section11 .zone_photos .img3 {
  position: absolute;
  z-index: 11;
  top: 0;
  left: 0;
  width: 25%;
}
.index #section11 .zone_photos .img4 {
  position: absolute;
  z-index: 11;
  top: 0;
  left: 27%;
  width: 25%;
}
.index #section11 .zone_photos .img5 {
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 0;
  width: 80%;
}
.index #section11 .zone_photos a {
  margin-right: 80px;
}
.index #section11 .filets {
  position: absolute;
  top: -200px;
  right: 20vw;
}
.index #section11 .filets .mot {
  top: 180px;
  right: 30px;
  transform-origin: 100% 50%;
  transform: rotate(-90deg);
}
.index #section11 .filets .ep2 {
  top: 0;
  height: 500px;
}

.index #section2 #carousel_accueil .carousel-indicators {
  right: -100px;
  bottom: 26px;
}

@media (max-width: 1199px) {
  .index #section2 {
    flex-direction: column-reverse;
  }
  .index #section2 .nav_accueil {
    flex-direction: column;
  }
  .index #section2 #carousel_accueil {
    height: 40vh;
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
  }
  .index #section2 #carousel_accueil .carousel-indicators {
    right: -85px;
  }
  .index #section2 .col1 {
    margin-top: 20px;
  }
  .index #section2 .col1 h1 {
    display: none;
  }
  .index #section4 .container {
    flex-direction: column;
  }
  .index #section5 {
    height: 40vh;
  }
  .index #section6 .img3 {
    display: none;
  }
  .index #section7 {
    margin-top: 60px;
  }
  .index #section7 .image img {
    transform: none;
  }
  .index #section8 img {
    width: 100%;
    height: 30vh;
    object-fit: cover;
  }
  .index #section10 .cadre {
    top: 10px;
    bottom: 10px;
    transform: none;
    width: 80vw;
  }
  .index #section10 .cadre img {
    display: none;
  }
  .index #section10 .cadre h2 {
    left: 10px;
    right: auto;
  }
  .index #section10 .cadre h2 .part2 {
    font-size: 3.75rem !important;
  }
  .index #section11 {
    margin-top: 120px;
  }
  .index #section11 .zone_photos {
    margin-left: 10%;
    width: 80%;
  }
  .index #section11 a {
    position: relative;
    margin: 0;
    top: -130px;
  }
}
.restaurant #section2 .col_gauche img {
  width: 100%;
}
.restaurant #section2 .col_droite .filets {
  flex-basis: 30px;
  flex-grow: 0;
  padding-left: 6px;
}
.restaurant #section2 .col_droite .filets .mot {
  top: 50px;
  left: 30px;
  transform-origin: 0% 50%;
  transform: rotate(-90deg);
}
.restaurant #section2 .col_droite .filets .ep1 {
  position: absolute;
  left: 3px;
  bottom: 0;
  height: 70%;
}
.restaurant #section2 .col_droite .filets .ep2 {
  top: -20%;
  height: 120%;
}
.restaurant #section2 .col_droite .surtexte {
  padding: 5vw;
  background-color: #dbdbdb;
  font-size: 1.5rem;
}
.restaurant #section2 .col_droite .paragraphe_gros {
  padding: 5vw;
}
.restaurant #section3 .col_gauche {
  padding-right: 20px;
}
.restaurant #section3 .col_droite {
  padding-left: 20px;
}
.restaurant #section4 {
  z-index: 10;
  margin-top: 50px;
}
.restaurant #section4 .col_gauche {
  padding-right: 5%;
}
.restaurant #section4 .col_droite img {
  width: 100%;
}
.restaurant #section4 .col_droite .img2 {
  position: absolute;
  left: 0;
  bottom: -10px;
  transform: translateY(100%);
}
.restaurant #section5 {
  margin-top: 70px;
}
.restaurant #section5 .zone_haut {
  height: 60vh;
}
.restaurant #section5 .zone_haut img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.restaurant #section5 .zone_haut .cadre_couleur {
  position: absolute;
  z-index: 1000;
  left: 21.3%;
  top: -20px;
  width: 28.8%;
  padding: 30px;
  border: 1px solid #000000;
  /* height: calc(60vh + 350px); */
}
.restaurant #section5 .zone_haut .cadre_couleur h2 {
  color: #ffffff;
}
.restaurant #section5 .zone_haut .cadre_couleur .zone_dans_image {
  position: relative;
  height: 60vh;
}
.restaurant #section5 .zone_haut .cadre_couleur .textes {
  height: 300px;
}
.restaurant #section5 .zone_bas {
  position: relative;
  min-height: 600px;
}
.restaurant #section5 .zone_bas img {
  position: absolute;
  width: 15vw;
  top: -20px;
  right: 6%;
}
.restaurant #section5 .zone_bas .filets {
  z-index: 1000;
  position: absolute;
  right: 25%;
}
.restaurant #section5 .zone_bas .filets .mot {
  top: 50px;
  right: 30px;
  transform-origin: 100% 50%;
  transform: rotate(-90deg);
}
.restaurant #section5 .zone_bas .filets .ep2 {
  z-index: 1000;
  top: -50px;
  height: 300px;
}

@media (max-width: 1199px) {
  .restaurant #section1 .absolute_centered {
    width: 90%;
  }
  .restaurant #section2 .col_gauche img {
    height: 40vh;
    object-fit: cover;
  }
  .restaurant #section3 .col_droite {
    padding: 0;
  }
  .restaurant #section4 a {
    margin-top: 20px !important;
  }
  .restaurant #section4 .img2 {
    bottom: auto !important;
  }
  .restaurant #section5 {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .restaurant #section5 .zone_haut img {
    height: auto;
    width: 100%;
  }
  .restaurant #section5 .zone_haut .cadre_couleur {
    position: relative !important;
    top: 20px;
    left: 5%;
    width: 90%;
  }
  .restaurant #section5 .zone_haut .cadre_couleur img {
    width: 50%;
  }
  .restaurant #section5 .zone_haut .cadre_couleur .photos {
    display: flex;
    position: relative;
    width: 100%;
  }
  .restaurant #section5 .zone_haut .cadre_couleur a {
    /* float: none !important; */
  }
  .restaurant #section5 .zone_haut .zone_dans_image {
    display: none;
  }
  .restaurant #section5 .zone_bas {
    min-height: 0px;
  }
  .restaurant #section5 .zone_bas .img2 {
    display: none;
  }
}
.chef_equipe #section2 {
  background: url(../images/chef_equipe_henrique.jpg) no-repeat left center;
  background-size: cover;
}
.chef_equipe #section2 .place_dessous {
  height: 20vh;
}
.chef_equipe #section2 .col_gauche img {
  padding: 10vw;
  width: 100%;
  opacity: 0;
}
.chef_equipe #section2 .col_droite .filets {
  flex-basis: 30px;
  flex-grow: 0;
  padding-left: 10px;
}
.chef_equipe #section2 .col_droite .filets .mot {
  top: 50px;
  left: 30px;
  transform-origin: 0% 50%;
  transform: rotate(-90deg);
  color: #ffffff;
}
.chef_equipe #section2 .col_droite .filets .ep1 {
  position: absolute;
  left: 6px;
  bottom: 0;
  height: 70%;
}
.chef_equipe #section2 .col_droite .filets .ep2 {
  top: -20%;
  height: 120%;
}
.chef_equipe #section2 .texte_henrique {
  position: absolute;
  top: 10%;
  padding: 3vw;
  padding-left: 50vw;
  background-color: rgba(0, 0, 0, 0.6);
}
.chef_equipe #section2 .texte_henrique p, .chef_equipe #section2 .texte_henrique h1 {
  color: #ffffff;
}
.chef_equipe #section2 .texte_henrique h1 {
  font-size: 4rem;
  font-family: "questa-grande";
}
.chef_equipe #section3 {
  padding: 70px 0;
}
.chef_equipe #section3 .col_gauche {
  padding-right: 50px;
}
.chef_equipe #section3 .col_gauche .titre {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0;
}
.chef_equipe #section3 .col_gauche .soustitre {
  font-size: 1.2rem;
  font-weight: 300;
  font-style: italic;
  margin-top: 0;
}
.chef_equipe #section3 .col_gauche .texte {
  margin-bottom: 30px;
}
.chef_equipe #section3 .col_droite {
  padding-left: 50px;
}
.chef_equipe #section3 .col_droite img {
  width: 72%;
  object-fit: cover;
  aspect-ratio: 1/1;
}
.chef_equipe #section3 .col_droite .img2, .chef_equipe #section3 .col_droite .img3 {
  margin-top: 30px;
}
.chef_equipe #section4 {
  height: 60vh;
  margin-bottom: 600px;
}
.chef_equipe #section4 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
.chef_equipe #section4 .cadre {
  position: absolute;
  z-index: 1000;
  left: 10%;
  top: 50%;
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.3);
}
.chef_equipe #section4 .cadre h1, .chef_equipe #section4 .cadre p {
  color: #ffffff;
}
.chef_equipe #section4 .cadre h1 {
  font-size: 4rem;
  font-weight: 700;
  font-family: "questa-grande";
}
.chef_equipe #section4 .cadre .signature {
  text-align: right;
  font-style: italic;
}
.chef_equipe #section4 .cadre .img_container {
  position: relative;
  z-index: 2;
}
.chef_equipe #section4 .cadre .img_container img {
  z-index: 2;
  width: 50%;
}
.chef_equipe #section4 .cadre .img_container .img5 {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(calc(100% + 20px));
}
.chef_equipe #section4 .cadre .img_container .img6 {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(calc(200% + 40px));
}
.chef_equipe #section4 .cadre .img7 {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 40%;
  top: 80%;
}
.chef_equipe #section4 .filet {
  position: absolute;
  height: 500px;
  width: 4px;
  background-color: #000000;
  right: 20%;
  top: 40%;
}
@media (max-width: 1199px) {
  .chef_equipe #section1 .absolute_centered {
    width: 90%;
  }
  .chef_equipe #section2 .col_gauche {
    display: none;
  }
  .chef_equipe #section2 .texte_henrique {
    position: relative;
    top: 10% px;
    padding: 3vw;
  }
  .chef_equipe #section3 .col_gauche {
    padding: 0;
  }
  .chef_equipe #section3 .col_droite {
    display: none;
  }
  .chef_equipe #section4 .filet {
    display: none;
  }
  .chef_equipe #section4 .cadre {
    left: 5%;
    width: 90%;
  }
  .chef_equipe #section4 .cadre .img7 {
    left: 0;
  }
}
.carte_menu #section2 .col_gauche .nav_menu {
  padding: 40px;
  text-align: center;
  list-style: none;
  font-size: 2rem;
}
.carte_menu #section2 .col_gauche .nav_menu a {
  font-weight: 300;
  text-decoration: none;
}
.carte_menu #section2 .col_gauche .nav_menu a:hover {
  text-decoration: underline;
  color: #000000;
}
.carte_menu #section2 .col_gauche .nav_menu a.active {
  font-weight: 700;
  text-decoration: underline;
}
.carte_menu #section2 .col_gauche .bloc_gris {
  padding: 4vw;
  background-color: #dbdbdb;
}
.carte_menu #section2 .col_gauche .bloc_gris .titre {
  font-weight: 700;
}
.carte_menu #section2 .col_droite {
  padding: 40px;
  padding-top: 0;
}
.carte_menu #section2 .col_droite .version_pdf {
  position: absolute;
  top: 20px;
  right: 20px;
}
.carte_menu #section2 .col_droite .version_pdf img {
  display: inline-block;
  width: 60px;
}
.carte_menu #section2 .col_droite .logo_bib_gourmand {
  position: absolute;
  width: 80px;
  top: 10px;
  right: 11vw;
}
.carte_menu #section2 .col_droite .menu {
  padding: 3vw;
  /* .titre_menu {
  	position: relative;
  	font-weight: 700;
  	margin-bottom: 15px;
  	margin-top: 40px;

  	span {
  		margin: 0 10px;
  	}

  	div {
  		display: inline-block;
  		transform: translateY(-8px);
  	}
  } */
}
.carte_menu #section2 .col_droite .menu td.titre {
  position: relative;
  font-size: 1rem !important;
  font-weight: 700;
  margin-bottom: 15px;
  padding-top: 40px !important;
}
.carte_menu #section2 .col_droite .menu td.titre span {
  margin: 0 10px;
}
.carte_menu #section2 .col_droite .menu td.titre div {
  display: inline-block;
  transform: translateY(-8px);
}
.carte_menu #section2 .col_droite .menu .menu_table .detail {
  display: block;
  font-size: 0.75rem;
  font-weight: 300;
}
.carte_menu #section2 .col_droite .menu .menu_table td {
  padding: 10px;
  padding-left: 0;
  font-size: 0.9rem;
}
.carte_menu #section2 .col_droite .menu .menu_table .prix {
  width: 100px;
  text-align: right;
  vertical-align: top;
  font-weight: 700;
}
.carte_menu #section2 .col_droite .menu h3 {
  margin-bottom: 40px;
  font-weight: 700;
}
.carte_menu #section2 .col_droite .menu h3 .soustitre {
  font-size: 1rem;
  font-weight: 300;
}
.carte_menu #section2 .col_droite .menu .bloc {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
}
.carte_menu #section2 .col_droite .menu .bloc p {
  margin: 0;
}
.carte_menu #section2 .col_droite .menu .bloc .ou {
  font-weight: 700;
}
.carte_menu #section2 .col_droite .menu .bloc .detail {
  display: block;
  font-size: 0.75rem;
  font-weight: 300;
}
.carte_menu #section2 .col_droite .menu .filet {
  display: block;
  width: 200px;
  height: 1px;
  margin: auto;
  background-color: #000000;
}
.carte_menu #section2 .col_droite .filets {
  flex-basis: 30px;
  flex-grow: 0;
  padding-left: 10px;
}
.carte_menu #section2 .col_droite .filets .mot {
  top: 50px;
  left: 30px;
  transform-origin: 0% 50%;
  transform: rotate(-90deg);
}
.carte_menu #section2 .col_droite .filets .ep1 {
  position: absolute;
  left: 6px;
  top: 30px;
  height: 500px;
}
.carte_menu #section2 .col_droite .filets .ep2 {
  top: -3%;
  height: 800px;
}
.carte_menu #section3 .texte {
  position: absolute;
  z-index: 1;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
}
.carte_menu #section3 .texte p {
  color: #ffffff;
  margin-bottom: 6px;
}
.carte_menu #section3 .texte .filet {
  width: 50%;
  height: 4px;
  background-color: #ffffff;
  margin: auto;
}
.carte_menu #section3 img {
  width: 100%;
  height: 40vw;
  object-fit: cover;
}
.carte_menu #section4 {
  padding: 40px 0;
}
.carte_menu #section4 p {
  padding-right: 10vw;
  padding-left: 100px;
}
.carte_menu #section4 .titre_type_1 {
  display: inline-block;
  margin-bottom: 20px;
}
.carte_menu #section4 .filets {
  padding-left: 10px;
}
.carte_menu #section4 .filets .mot {
  bottom: 100px;
  right: 30px;
  transform-origin: 100% 50%;
  transform: rotate(-90deg);
}
.carte_menu #section4 .filets .ep2 {
  top: -20%;
  height: 110%;
}
.carte_menu #section5 {
  margin: 60px 0;
}
.carte_menu #section5 .cadre {
  position: absolute;
  height: 10vw;
  width: 10vw;
  left: 30%;
  border: 1px solid #000000;
  transform: translateY(-30px);
}
.carte_menu #section5 .col_droite {
  transform: translate(-50px, -50px);
}
.carte_menu #section5 .col_droite img {
  float: left;
  width: 45%;
  margin-left: 20px;
}
.carte_menu #section5 .col_droite .img2 {
  position: absolute;
  top: -20px;
  left: 0;
  transform: translateY(-100%);
}
.carte_menu #section5 .col_droite .img5 {
  position: absolute;
  top: 20px;
  right: 0;
  transform: translateY(100%);
}
.carte_menu .content_carte_vins .version_pdf {
  top: 100px !important;
  left: 50%;
}
.carte_menu .content_carte_vins .version_pdf img {
  width: 150px !important;
}
@media (max-width: 1199px) {
  .carte_menu .content_carte_vins .version_pdf {
    display: inline-block;
    position: relative !important;
    top: 0 !important;
    right: initial !important;
    transform: translateX(-50%);
  }
  .carte_menu .content_carte_vins .version_pdf img {
    width: 150px;
  }
}

@media (max-width: 1199px) {
  .carte_menu #section2 .col_gauche {
    display: flex;
    flex-direction: column-reverse;
  }
  .carte_menu #section2 .col_droite {
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .carte_menu #section2 .col_droite .version_pdf {
    top: -60px;
    right: 10px;
  }
  .carte_menu #section2 .col_droite .logo_bib_gourmand {
    right: -10px;
  }
  .carte_menu #section2 .content_menu_terroir h3 {
    padding-right: 60px;
  }
  .carte_menu #section4 p {
    padding: 0;
  }
  .carte_menu #section5 .cadre {
    display: none;
  }
  .carte_menu #section5 .img2, .carte_menu #section5 .img3, .carte_menu #section5 .img4, .carte_menu #section5 .img5 {
    position: relative !important;
    margin: 0 !important;
    float: left !important;
    transform: none !important;
    width: 50% !important;
    top: 0 !important;
  }
  .carte_menu #section5 .col_droite {
    transform: none;
    width: 100% !important;
  }
}
.ils_parlent_de_nous .paragraphe_gros {
  font-size: 1.2rem;
}
.ils_parlent_de_nous #section2 .col_gauche .bloc_gris {
  padding: 4vw;
  background-color: #dbdbdb;
}
.ils_parlent_de_nous #section2 .col_gauche .bloc_gris .titre {
  font-weight: 700;
}
.ils_parlent_de_nous #section2 .col_gauche .signature {
  font-size: 0.85rem;
  text-align: right;
  margin-bottom: 30px;
}
.ils_parlent_de_nous #section2 .col_droite {
  padding: 40px;
}
.ils_parlent_de_nous #section2 .col_droite .filets {
  flex-basis: 30px;
  flex-grow: 0;
  padding-left: 10px;
  transform: translateX(-25px);
}
.ils_parlent_de_nous #section2 .col_droite .filets .mot {
  top: 100px;
  left: 30px;
  transform-origin: 0% 50%;
  transform: rotate(-90deg);
}
.ils_parlent_de_nous #section2 .col_droite .filets .ep1 {
  position: absolute;
  left: 6px;
  top: 30px;
  height: 300px;
}
.ils_parlent_de_nous #section2 .col_droite .filets .ep2 {
  top: -20%;
  height: 600px;
}
.ils_parlent_de_nous #section2 .col_droite img {
  margin-top: 20px;
}
.ils_parlent_de_nous #section2 .col_droite .col_droite {
  padding-left: 30px;
}
.ils_parlent_de_nous #section2 .col_droite .dans_la_presse {
  margin-top: 30px;
}
.ils_parlent_de_nous #section2 .col_droite .dans_la_presse a {
  display: block;
  text-decoration: none;
  margin-top: 6px;
  font-size: 0.9rem;
}
@media (max-width: 1199px) {
  .ils_parlent_de_nous #section1 .absolute_centered {
    width: 90%;
  }
  .ils_parlent_de_nous #section2 .col_droite {
    padding: 20px;
    padding-right: 0px;
  }
}
.village_yvoire #section1 h1, .village_yvoire #section1 h3 {
  color: #000000;
}
.village_yvoire #section2 .col_gauche .paragraphe_gros {
  padding: 3vw;
  padding-right: 6vw;
  margin: 0;
}
.village_yvoire #section2 .col_gauche .bloc_gris {
  padding: 4vw;
  background-color: #dbdbdb;
}
.village_yvoire #section2 .col_gauche .bloc_gris .titre {
  font-weight: 700;
}
.village_yvoire #section2 .col_gauche .bloc_gris p {
  padding-right: 6vw;
}
.village_yvoire #section2 .col_gauche img {
  width: 75%;
  margin-top: 40px;
}
.village_yvoire #section2 .col_droite {
  padding: 4vw;
}
.village_yvoire #section2 .col_droite .filets {
  position: absolute;
  left: 0px;
  flex-basis: 30px;
  flex-grow: 0;
  padding-left: 10px;
  transform: translateX(-25px);
}
.village_yvoire #section2 .col_droite .filets .mot {
  top: 100px;
  left: 30px;
  transform-origin: 0% 50%;
  transform: rotate(-90deg);
}
.village_yvoire #section2 .col_droite .filets .ep1 {
  position: absolute;
  left: 6px;
  top: 30px;
  height: 300px;
}
.village_yvoire #section2 .col_droite .filets .ep2 {
  top: -100px;
  height: 600px;
}
.village_yvoire #section2 .col_droite img {
  margin-top: 10px;
}
.village_yvoire #section2 .col_droite .col_droite {
  padding-left: 30px;
}
.village_yvoire #section2 .col_droite .texte {
  padding-top: 40px;
  padding-left: 40px;
}
.village_yvoire #section3 {
  margin-top: 100px;
}
.village_yvoire #section3 .img1, .village_yvoire #section3 .img2 {
  position: absolute;
  height: 15vw;
  width: 15vw;
  top: -50px;
}
.village_yvoire #section3 .img1 {
  left: 50px;
}
.village_yvoire #section3 .img2 {
  left: calc(70px + 15vw);
}
.village_yvoire #section3 .cadre {
  position: absolute;
  width: 25%;
  height: 80%;
  right: 10%;
  top: -110px;
  padding: 30px;
  padding-top: 10px;
  border: 2px solid #000000;
}
.village_yvoire #section3 .cadre h2, .village_yvoire #section3 .cadre .part2 {
  line-height: 1.5rem !important;
}
.village_yvoire #section3 .cadre .soustitre {
  font-size: 1rem;
}
.village_yvoire #section4 {
  height: 300px;
}
.village_yvoire #section4 .zone_photo {
  position: absolute;
  right: 30%;
  top: -80px;
  width: 15%;
  text-align: center;
  font-style: italic;
}
.village_yvoire #section4 .zone_photo p {
  margin-top: 10px;
}
.village_yvoire #section4 .filet {
  position: absolute;
  right: 20%;
  top: -350px;
  width: 3px;
  height: 500px;
  background-color: #000000;
}
@media (max-width: 1199px) {
  .village_yvoire #section1 .absolute_centered {
    width: 90%;
  }
  .village_yvoire #section2 .col_gauche img {
    width: 100%;
  }
  .village_yvoire #section2 .col_droite {
    /* padding: 20px;
    padding-right: 0;
    padding-left: 30px; */
  }
  .village_yvoire #section2 .col_droite .row img {
    display: none;
  }
  .village_yvoire #section2 .col_droite .texte {
    padding-left: 0;
  }
  .village_yvoire #section3 {
    margin-top: 150px;
  }
  .village_yvoire #section3 .cadre {
    width: 90%;
    left: 5%;
    right: auto;
  }
  .village_yvoire #section3 .img1, .village_yvoire #section3 .img2 {
    position: relative;
    display: inline-block;
    width: 49%;
    height: 30vh;
    left: 1vw;
    top: auto;
    object-fit: cover;
  }
  .village_yvoire #section4 {
    height: auto;
  }
  .village_yvoire #section4 .filet {
    display: none;
  }
  .village_yvoire #section4 .zone_photo {
    position: relative;
    top: 20px;
    left: 15%;
    width: 60%;
    margin-bottom: 100px;
    /* top: 20px;
    right: 50%;
    transform: translateX(50%);
    width: 60%; */
  }
}
.actualites #section1 .col1 {
  padding: 5vw;
  text-align: center;
}
.actualites #section1 .col1 p {
  margin: 5px;
  font-size: 1.3rem;
}
.actualites #section1 .col1 h1 {
  color: #000000;
  opacity: 1;
  font-size: 3.5rem;
}
.actualites #section1 .col1 h1 span {
  font-family: "questa-grande";
}
.actualites #section1 .col1 h1 .part1 {
  font-style: italic;
}
.actualites #section1 .col1 h1 .part2 {
  font-weight: 700;
}
.actualites #section1 .col1 h5 {
  font-weight: 700;
  margin-top: 30px;
}
.actualites #section1 .col1 .yvoire {
  margin-top: 20px;
  font-size: 0.8rem;
  font-weight: 700;
}
.actualites #section1 .col1 .filet {
  margin: auto;
  height: 2px;
  width: 46px;
  background-color: #000000;
}
.actualites #section1 .col2 {
  flex-basis: 30px;
  flex-grow: 0;
  padding: 30px 0 !important;
}
.actualites #section1 .col2 .filet_vertical._1 {
  top: 220px;
  height: 70%;
}
.actualites #section1 .col2 .filet_vertical._2 {
  top: 200px;
}
.actualites #section1 .col2 .filet_vertical._2 .mot {
  z-index: 100;
  top: 0px;
  left: 30px;
  transform-origin: 0 50%;
  transform: rotate(-90deg) translateX(-100%);
}
.actualites #section1 .col3 .reseaux {
  position: absolute;
  bottom: 30px;
  right: 50px;
}
.actualites #section1 .col3 .reseaux p {
  margin: 0;
}
.actualites #section1 .col3 .reseaux a {
  font-size: 1.4rem;
}
.actualites #section2 {
  margin-bottom: 100px;
}
.actualites #section2 .col_gauche .bloc_gris {
  padding: 4vw;
  background-color: #dbdbdb;
}
.actualites #section2 .col_gauche .bloc_gris .titre {
  font-weight: 700;
}
.actualites #section2 .col_gauche .signature {
  font-size: 0.85rem;
  text-align: right;
  margin-bottom: 30px;
}
.actualites #section2 .col_droite {
  padding: 6vw;
}
.actualites #section2 .col_droite .news_container {
  margin-bottom: 100px;
}
.actualites #section2 .col_droite .news_container h1 {
  font-family: "questa-grande";
  font-style: italic;
}
.actualites #section2 .col_droite .news_container h2 {
  margin-top: 26px;
  margin-bottom: 20px;
  font-size: 2.6rem;
}
.actualites #section2 .col_droite .news_container h3 {
  font-family: "questa-grande";
  font-size: 2rem;
  margin: 14px 0;
}
.actualites #section2 .col_droite .news_container h4 {
  font-size: 1.4rem;
  margin: 10px 0;
}
.actualites #section2 .col_droite .news_container .row {
  margin: 1.5rem 0;
}
.actualites #section2 .col_droite .news_container .row > div:first-child {
  padding-left: 0;
}
.actualites #section2 .col_droite .news_container .row > div:last-child {
  padding-right: 0;
}
.actualites #section2 .col_droite .news_container .row .col_texte h3:first-child {
  margin: 0;
}
.actualites #section2 .col_droite .news_container .row .col_texte h4:first-child {
  margin: 0;
}
.actualites #section2 .col_droite .news_container .row .col_texte p:last-child {
  margin: 0;
}
.actualites #section2 .col_droite .news_container .row .col_image > div {
  position: relative;
  height: 100%;
}
.actualites #section2 .col_droite .news_container .row .col_image img {
  position: absolute;
}
.actualites #section2 .col_droite .news_container .row .col_image img.contain {
  object-fit: contain;
}
@media (max-width: 1199px) {
  .actualites #section2 .col_droite .news_container .row .col_texte, .actualites #section2 .col_droite .news_container .row .col_image {
    padding: 0;
  }
  .actualites #section2 .col_droite .news_container .row .col_texte {
    order: 2;
  }
  .actualites #section2 .col_droite .news_container .row .col_image {
    order: 1;
    min-height: 300px;
    margin-bottom: 30px;
  }
}
.actualites #section2 .col_droite .date {
  text-align: right;
}
.actualites #section2 .col_droite .titre {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 30px;
}
.actualites #section2 .col_droite .share {
  text-align: right;
  margin-top: 10px;
}
.actualites #section2 .col_droite .share p {
  font-size: 1rem;
  color: #a8a8a8;
  margin-right: 10px;
  transform: translateY(-4px);
}
.actualites #section2 .col_droite .share * {
  display: inline-block;
}
.actualites #section2 .col_droite .bloc {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
}
.actualites #section2 .col_droite .bloc p {
  margin: 0;
}
.actualites #section2 .col_droite .bloc .ou {
  font-weight: 700;
}
.actualites #section2 .col_droite .bloc .detail {
  display: block;
  font-size: 0.75rem;
  font-weight: 300;
}
.actualites #section2 .col_droite .filet {
  display: block;
  width: 200px;
  height: 1px;
  margin: auto;
  background-color: #000000;
}

@media (max-width: 1199px) {
  .actualites #section1 .absolute_centered {
    width: 90%;
  }
}
.contact_acces .confirmations {
  width: 100%;
  padding: 20px;
  height: auto;
  background-color: #ffffff;
  margin-bottom: 20px;
  display: none;
}
.contact_acces #section2 {
  background-color: #dbdbdb;
}
.contact_acces #section2 .col_gauche .bloc_gris {
  padding: 4vw;
  padding-left: 10vw;
  padding-right: 10vw;
  background-color: #dbdbdb;
}
.contact_acces #section2 .col_gauche .bloc_gris .titre {
  font-weight: 700;
}
.contact_acces #section2 .col_gauche .signature {
  font-size: 0.85rem;
  text-align: right;
  margin-bottom: 30px;
}
.contact_acces #section2 .col_gauche #carte_googlemaps {
  height: 300px;
}
.contact_acces #section2 .col_droite {
  padding: 40px;
}
.contact_acces #section2 .col_droite .filets {
  position: absolute;
  top: -130px;
  left: 0;
  width: auto;
  flex-basis: 30px;
  flex-grow: 0;
  padding-left: 4px;
  transform: translateX(-25px);
}
.contact_acces #section2 .col_droite .filets .mot {
  top: 100px;
  left: 30px;
  transform-origin: 0% 50%;
  transform: rotate(-90deg);
}
.contact_acces #section2 .col_droite .filets .ep1 {
  position: absolute;
  left: 0px;
  top: 80px;
  height: 300px;
}
.contact_acces #section2 .col_droite .filets .ep2 {
  top: 20%;
  height: 600px;
}
.contact_acces #section2 .col_droite .form_container {
  width: 100%;
}
.contact_acces #section2 .col_droite .form_container .submit {
  cursor: pointer;
  float: right;
  color: #000000;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  padding-bottom: 4px;
  border: none;
  border-bottom: 2px solid #000000;
  background: none;
  border-radius: 0;
}
.contact_acces #section2 .col_droite .form_container .submit:hover {
  color: #000000;
  text-decoration: none;
}
.contact_acces #section2 .col_droite .form_container .submit:disabled {
  cursor: initial;
  color: gray;
  border-bottom: 2px solid gray;
}
.contact_acces #section2 .col_droite .form_container textarea {
  height: 200px;
}
.contact_acces #section3 {
  margin-top: 50px;
}
.contact_acces #section3 .titre_type_1 {
  text-align: left;
  margin-left: 15vw;
}
.contact_acces #section3 .titre_type_1 .part1 {
  font-family: "questa-grande";
  font-size: 2rem;
  font-weight: 300;
}
.contact_acces #section3 .titre_type_1 .part2 {
  font-size: 4rem;
  margin-left: 40px;
}
.contact_acces #section4 {
  margin-top: 20px;
  margin-bottom: 50px;
}
.contact_acces #section4 p {
  font-size: 0.85rem;
}
.contact_acces #section4 .col2 {
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}
.contact_acces #section4 > div {
  padding: 0 30px;
}
.contact_acces #section5 {
  margin-top: 150px;
}
.contact_acces #section5 .cadre {
  position: absolute;
  left: 10vw;
  bottom: 0;
  z-index: 10;
  padding: 20vw;
  border: 2px solid #000000;
  transform: translateY(30%);
}
.contact_acces #section5 .cadre img {
  position: absolute;
  width: 40%;
  top: 0;
  right: 5%;
}
.contact_acces #section5 .cadre .img1 {
  transform: translateY(-80%);
}
.contact_acces #section5 .cadre .img2 {
  transform: translateY(-185%);
}
.contact_acces #section5 .cadre h2 {
  position: absolute;
  right: -120px;
  top: 20%;
  color: #ffffff;
  line-height: 3rem;
}
.contact_acces #section5 .cadre h2 .part2 {
  font-size: 4.5rem;
}
.contact_acces #section5 .cadre h2 .part1 {
  font-size: 1rem;
  margin-right: 200px;
}
.contact_acces #section6 {
  margin: 80px 0;
}
.contact_acces #section6 .zone_photos {
  position: relative;
  margin-left: 20%;
  width: 50%;
  height: 32vw;
}
.contact_acces #section6 .zone_photos .img3 {
  position: absolute;
  z-index: 11;
  top: 0;
  left: 0;
  width: 25%;
}
.contact_acces #section6 .zone_photos .img4 {
  position: absolute;
  z-index: 11;
  top: 0;
  left: 27%;
  width: 25%;
}
.contact_acces #section6 .zone_photos .img5 {
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 0;
  width: 80%;
}
.contact_acces #section6 .zone_photos a {
  margin-right: 80px;
}
.contact_acces #section6 .filets {
  position: absolute;
  top: -200px;
  right: 20vw;
}
.contact_acces #section6 .filets .mot {
  top: 180px;
  right: 30px;
  transform-origin: 100% 50%;
  transform: rotate(-90deg);
}
.contact_acces #section6 .filets .ep2 {
  top: 0;
  height: 500px;
}
@media (max-width: 1199px) {
  .contact_acces #section1 .absolute_centered {
    width: 90%;
  }
}
.app_container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 5vw;
  color: #383938;
}
.app_container .hidden {
  display: none !important;
}
.app_container h2 {
  font-size: 1.3rem;
  text-align: center;
}
.app_container .lang_en {
  color: #383938;
}
.app_container .lang_fr {
  color: #898c8e;
}
.app_container .filet {
  width: 50px;
  height: 4px;
  background-color: #383938;
  margin-top: 12px;
  margin-bottom: 30px;
}
.app_container .button {
  display: inline-block;
  cursor: pointer;
}
.app_container .button img {
  width: 55px;
}
.app_container .homescreen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.app_container .homescreen p {
  text-align: center;
  font-weight: 700;
  margin: 0;
}
.app_container .homescreen .back_container, .app_container .homescreen .navigate_container {
  margin-bottom: 20px;
}
.app_container .main_menu {
  text-align: center;
}
.app_container .main_menu .entete p {
  font-weight: 500;
  font-size: 1.2rem;
  margin: 0;
}
.app_container .main_menu .filet {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}
.app_container .main_menu .nav_menu {
  list-style: none;
  padding: 0;
}
.app_container .main_menu .nav_menu a {
  color: #383938;
  font-size: calc(1.2rem + 2vw);
}
.app_container .nav_container {
  align-self: flex-start;
  justify-self: flex-start;
  padding: 10px;
  margin-bottom: 10px;
}
.app_container .lvl1_contents {
  min-height: calc(100vh - 10vw);
  min-width: 90vw;
}
.app_container .lvl1_contents .js-menu-content {
  text-align: center;
}
.app_container .lvl1_contents .content_carte {
  text-align: left;
}
.app_container .lvl1_contents .titre {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 20px;
}
.app_container .lvl1_contents .titre span {
  margin-right: 6px;
}
.app_container .lvl1_contents .entry {
  margin-bottom: 26px;
}
.app_container .lvl1_contents .entry .nom {
  margin-bottom: 6px;
}
.app_container .lvl1_contents .entry .nom .detail {
  font-style: italic;
  font-size: 0.85rem;
}
.app_container .lvl1_contents .entry .prix {
  text-align: right;
  font-size: 1.2rem;
  font-weight: 700;
}
.app_container .lvl1_contents h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 20px;
}
.app_container .lvl1_contents .bloc p, .app_container .lvl1_contents .bloc span {
  font-family: "adelle-sans", sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
}
.app_container .lvl1_contents .filet {
  margin: 30px auto;
}
.app_container .lvl1_contents .filet:last-child {
  display: none;
}
.app_container .version_pdf {
  display: block;
  width: 75%;
  max-width: 400px;
  margin: auto;
}

div#tarteaucitronAlertBig:focus {
  outline: 0;
}

.tarteaucitron-modal-open {
  overflow: hidden;
  height: 100%;
}

#tarteaucitronContentWrapper {
  display: unset;
}

/** 14042021 **/
span.tarteaucitronReadmoreSeparator {
  display: inline !important;
}

/******/
/** 09052021 **/
.tarteaucitronName .tacCurrentStatus, .tarteaucitronName .tarteaucitronReadmoreSeparator {
  color: #333 !important;
  font-size: 12px !important;
  text-transform: capitalize;
}

/**************/
/** 27032021 **/
button.tarteaucitron-toggle-group {
  display: block;
}

span.tarteaucitronH3 {
  font-weight: 700 !important;
}

#tarteaucitron #tarteaucitronServices_mandatory .tarteaucitronH3 {
  font-weight: 500 !important;
  font-size: 14px;
  margin-top: 7px;
}

.tarteaucitronLine {
  border-left: 0px solid transparent !important;
}

/*****/
/** BETTER MOBILE MODE **/
@media screen and (max-width: 767px) {
  html body #tarteaucitronRoot #tarteaucitron ul#tarteaucitronServices_mandatory .tarteaucitronDeny {
    display: none !important;
  }
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button,
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronAsk,
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronName {
    width: 100% !important;
    display: block !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    box-sizing: border-box !important;
    max-width: 100% !important;
    margin-bottom: 8px !important;
  }
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder ul .tarteaucitronLine {
    padding: 16px !important;
  }
  html body #tarteaucitronRoot #tarteaucitron div#tarteaucitronMainLineOffset .tarteaucitronName {
    display: none !important;
  }
  #tarteaucitronServices_mandatory li.tarteaucitronLine .tarteaucitronName span {
    width: 100% !important;
    display: inline-block;
  }
  li.tarteaucitronLine .tarteaucitronName span {
    width: 80% !important;
    display: inline-block;
  }
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group {
    width: 10% !important;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 0px;
    padding: 10px 0;
  }
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group:before {
    content: "▾";
    font-weight: 700;
    font-size: 14px;
  }
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder .tarteaucitronIsExpanded button.tarteaucitron-toggle-group:before {
    content: "▴";
  }
}
@media screen and (min-width: 768px) {
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group:after {
    content: "▾";
    font-weight: 700;
    font-size: 14px;
    margin-left: 15px;
  }
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder .tarteaucitronIsExpanded button.tarteaucitron-toggle-group:after {
    content: "▴";
    margin-left: 15px;
  }
}
/****/
/***
* Reset CSS
*/
#tarteaucitronRoot div, #tarteaucitronRoot span, #tarteaucitronRoot applet, #tarteaucitronRoot object, #tarteaucitronRoot iframe, #tarteaucitronRoot h1, #tarteaucitronRoot h2, #tarteaucitronRoot h3, #tarteaucitronRoot h4, #tarteaucitronRoot h5, #tarteaucitronRoot h6, #tarteaucitronRoot p, #tarteaucitronRoot blockquote, #tarteaucitronRoot pre, #tarteaucitronRoot a, #tarteaucitronRoot abbr, #tarteaucitronRoot acronym, #tarteaucitronRoot address, #tarteaucitronRoot big, #tarteaucitronRoot cite, #tarteaucitronRoot code, #tarteaucitronRoot del, #tarteaucitronRoot dfn, #tarteaucitronRoot em, #tarteaucitronRoot img, #tarteaucitronRoot ins, #tarteaucitronRoot kbd, #tarteaucitronRoot q, #tarteaucitronRoot s, #tarteaucitronRoot samp, #tarteaucitronRoot small, #tarteaucitronRoot strike, #tarteaucitronRoot strong, #tarteaucitronRoot sub, #tarteaucitronRoot sup, #tarteaucitronRoot tt, #tarteaucitronRoot var, #tarteaucitronRoot b, #tarteaucitronRoot u, #tarteaucitronRoot i, #tarteaucitronRoot center, #tarteaucitronRoot dl, #tarteaucitronRoot dt, #tarteaucitronRoot dd, #tarteaucitronRoot ol, #tarteaucitronRoot ul, #tarteaucitronRoot li, #tarteaucitronRoot fieldset, #tarteaucitronRoot form, #tarteaucitronRoot label, #tarteaucitronRoot legend, #tarteaucitronRoot table, #tarteaucitronRoot caption, #tarteaucitronRoot tbody, #tarteaucitronRoot tfoot, #tarteaucitronRoot thead, #tarteaucitronRoot tr, #tarteaucitronRoot th, #tarteaucitronRoot td, #tarteaucitronRoot article, #tarteaucitronRoot aside, #tarteaucitronRoot canvas, #tarteaucitronRoot details, #tarteaucitronRoot embed, #tarteaucitronRoot figure, #tarteaucitronRoot figcaption, #tarteaucitronRoot footer, #tarteaucitronRoot header, #tarteaucitronRoot hgroup, #tarteaucitronRoot menu, #tarteaucitronRoot nav, #tarteaucitronRoot output, #tarteaucitronRoot ruby, #tarteaucitronRoot section, #tarteaucitronRoot summary, #tarteaucitronRoot time, #tarteaucitronRoot mark, #tarteaucitronRoot audio, #tarteaucitronRoot video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  /*background: initial;*/
  text-align: initial;
  text-shadow: initial;
}

/* Animation */
#tarteaucitronRoot * {
  transition: border 300ms, background 300ms, opacity 200ms, box-shadow 400ms;
}

/* HTML5 display-role reset for older browsers */
#tarteaucitronRoot article, #tarteaucitronRoot aside, #tarteaucitronRoot details, #tarteaucitronRoot figcaption, #tarteaucitronRoot figure, #tarteaucitronRoot footer, #tarteaucitronRoot header, #tarteaucitronRoot hgroup, #tarteaucitronRoot menu, #tarteaucitronRoot nav, #tarteaucitronRoot section {
  display: block;
}

#tarteaucitronRoot ol, #tarteaucitronRoot ul {
  list-style: none;
}

#tarteaucitronRoot blockquote, #tarteaucitronRoot q {
  quotes: none;
}

#tarteaucitronRoot blockquote:before, #tarteaucitronRoot blockquote:after, #tarteaucitronRoot q:before, #tarteaucitronRoot q:after {
  content: "";
  content: none;
}

#tarteaucitronRoot table {
  border-collapse: collapse;
  border-spacing: 0;
}

#tarteaucitronRoot a:focus-visible, #tarteaucitronRoot button:focus-visible {
  outline: 3px dashed #3d86d8;
}

/***
 * Better scroll management
 */
div#tarteaucitronMainLineOffset {
  margin-top: 0 !important;
}

div#tarteaucitronServices {
  margin-top: 21px !important;
}

#tarteaucitronServices::-webkit-scrollbar {
  width: 5px;
}

#tarteaucitronServices::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}

#tarteaucitronServices::-webkit-scrollbar-thumb {
  background-color: #ddd;
  outline: 0px solid slategrey;
}

div#tarteaucitronServices {
  box-shadow: 0 40px 60px #545454;
}

/***
 * Responsive layout for the control panel
 */
@media screen and (max-width: 479px) {
  #tarteaucitron .tarteaucitronLine .tarteaucitronName {
    width: 90% !important;
  }
  #tarteaucitron .tarteaucitronLine .tarteaucitronAsk {
    float: left !important;
    margin: 10px 15px 5px;
  }
}
@media screen and (max-width: 767px) {
  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer, #tarteaucitron {
    background: #fff;
    border: 0 !important;
    bottom: 0 !important;
    height: 100% !important;
    left: 0 !important;
    margin: 0 !important;
    max-height: 100% !important;
    max-width: 100% !important;
    top: 0 !important;
    width: 100% !important;
  }
  #tarteaucitron .tarteaucitronBorder {
    border: 0 !important;
  }
  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
    border: 0 !important;
  }
  #tarteaucitron #tarteaucitronServices .tarteaucitronTitle {
    text-align: left !important;
  }
  .tarteaucitronName .tarteaucitronH2 {
    max-width: 80%;
  }
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
    text-align: center !important;
  }
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk button {
    margin-bottom: 5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  #tarteaucitron {
    border: 0 !important;
    left: 0 !important;
    margin: 0 5% !important;
    max-height: 80% !important;
    width: 90% !important;
  }
}
/***
 * Common value
 */
#tarteaucitronRoot div#tarteaucitron {
  left: 0;
  right: 0;
  margin: auto;
}

#tarteaucitronRoot button#tarteaucitronBack {
  background: #eee;
}

#tarteaucitron .clear {
  clear: both;
}

#tarteaucitron a {
  color: rgb(66, 66, 66);
  font-size: 11px;
  font-weight: 700;
  text-decoration: none;
}

#tarteaucitronRoot button {
  background: transparent;
  border: 0;
}

#tarteaucitronAlertBig strong, #tarteaucitronAlertSmall strong,
#tarteaucitronAlertBig a, #tarteaucitronAlertSmall a {
  color: #fff;
}

#tarteaucitron strong {
  font-size: 22px;
  font-weight: 500;
}

#tarteaucitron ul {
  padding: 0;
}

#tarteaucitron .tarteaucitronH1, #tarteaucitron .tarteaucitronH2, #tarteaucitron .tarteaucitronH3, #tarteaucitron .tarteaucitronH4, #tarteaucitron .tarteaucitronH5, #tarteaucitron .tarteaucitronH6 {
  display: block;
}

.cookie-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/***
 * Root div added just before </body>
 */
#tarteaucitronRoot {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

#tarteaucitronRoot * {
  box-sizing: initial;
  color: #333;
  font-family: sans-serif !important;
  font-size: 14px;
  line-height: normal;
  vertical-align: initial;
}

#tarteaucitronRoot .tarteaucitronH1 {
  font-size: 1.5em;
  text-align: center;
  color: #fff;
  margin: 15px 0 28px;
}

#tarteaucitronRoot .tarteaucitronH2 {
  display: inline-block;
  margin: 12px 0 0 10px;
  color: #fff;
}

#tarteaucitronCookiesNumberBis.tarteaucitronH2 {
  margin-left: 0;
}

/***
 * Control panel
 */
#tarteaucitronBack {
  background: #fff;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2147483646;
}

#tarteaucitron {
  display: none;
  max-height: 80%;
  left: 50%;
  margin: 0 auto 0 -430px;
  padding: 0;
  position: fixed;
  top: 6%;
  width: 860px;
  z-index: 2147483647;
}

#tarteaucitron .tarteaucitronBorder {
  background: #fff;
  border: 2px solid #333;
  border-top: 0;
  height: auto;
  overflow: auto;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronClosePanelCookie,
#tarteaucitron #tarteaucitronClosePanel {
  background: #333333;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
  padding: 4px 0;
  position: absolute;
  right: 0;
  text-align: center;
  width: 70px;
}

#tarteaucitron #tarteaucitronDisclaimer {
  color: #555;
  font-size: 12px;
  margin: 15px auto 0;
  width: 80%;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden,
#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
  background: rgba(51, 51, 51, 0.07);
}

#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
  display: none;
  position: relative;
}

#tarteaucitronCookiesList .tarteaucitronH3.tarteaucitronTitle {
  width: 100%;
  box-sizing: border-box;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle button,
#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  margin: 20px 0px 0px;
  padding: 5px 20px;
  text-align: left;
  width: auto;
  background: #333;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a {
  color: #fff;
  font-weight: 500;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a:hover,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a:hover {
  text-decoration: none !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a {
  font-size: 22px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a {
  font-size: 14px;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle {
  padding: 5px 10px;
  margin: 0;
}

#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
  color: #fff;
  display: none;
  font-size: 12px;
  font-weight: 500;
  margin-top: 0;
  max-width: 270px;
  padding: 20px;
  position: absolute;
  z-index: 2147483647;
}

#tarteaucitron #tarteaucitronInfo a {
  color: #fff;
  text-decoration: underline;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine:hover {
  background: rgba(51, 51, 51, 0.2);
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine {
  background: rgba(51, 51, 51, 0.1);
  border-left: 5px solid transparent;
  margin: 0;
  overflow: hidden;
  padding: 15px 5px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed {
  border-color: #1B870B;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied {
  border-color: #9C1A1A;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
  background: #333;
  border: 3px solid #333;
  border-left: 9px solid #333;
  border-top: 5px solid #333;
  margin-bottom: 0;
  margin-top: 21px;
  position: relative;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine:hover {
  background: #333;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName {
  margin-left: 15px;
  margin-top: 2px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName button {
  color: #fff;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronAsk {
  margin-top: 0px !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName {
  display: inline-block;
  float: left;
  margin-left: 10px;
  text-align: left;
  width: 50%;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName a:hover {
  text-decoration: underline;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
  display: inline-block;
  float: right;
  margin: 7px 15px 0;
  text-align: right;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow,
#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny,
.tac_activate .tarteaucitronAllow {
  background: gray;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  padding: 6px 10px;
  text-align: center;
  text-decoration: none;
  width: auto;
  border: 0;
}

#tarteaucitron #tarteaucitronServices #tarteaucitronAllAllowed.tarteaucitronIsSelected {
  background-color: #1B870B;
  opacity: 1;
}

#tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied.tarteaucitronIsSelected,
#tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied2.tarteaucitronIsSelected {
  background-color: #9C1A1A;
  opacity: 1;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed .tarteaucitronAllow {
  background-color: #1B870B;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied .tarteaucitronDeny {
  background-color: #9C1A1A;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName .tarteaucitronListCookies {
  color: #333;
  font-size: 12px;
}

#tarteaucitron .tarteaucitronH3 {
  font-size: 18px;
}

#tarteaucitron #tarteaucitronMainLineOffset .tarteaucitronName {
  width: auto !important;
  margin-left: 0 !important;
  font-size: 14px;
}

span#tarteaucitronDisclaimerAlert {
  padding: 0 10px;
  display: inline-block;
}

#tarteaucitron .tarteaucitronBorder, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden, #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
  border-color: #333 !important;
}

/***
 * Big alert
 */
.tarteaucitronAlertBigTop {
  top: 0;
}

.tarteaucitronAlertBigBottom {
  bottom: 0;
}

#tarteaucitronRoot #tarteaucitronAlertBig {
  background: #333;
  color: #fff;
  display: none;
  font-size: 15px !important;
  left: 0;
  position: fixed;
  box-sizing: content-box;
  z-index: 2147483645;
  text-align: center;
  padding: 10px 0 10px 0;
  margin: auto;
  width: 100%;
}

#tarteaucitronAlertBig #tarteaucitronPrivacyUrl,
#tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog,
#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert,
#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
  font: 15px verdana;
  color: #fff;
}

#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
  font-weight: 700;
}

#tarteaucitronAlertBig #tarteaucitronPrivacyUrl,
#tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog {
  cursor: pointer;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert,
#tarteaucitronAlertBig #tarteaucitronPersonalize,
#tarteaucitronAlertBig #tarteaucitronPersonalize2,
.tarteaucitronCTAButton,
#tarteaucitron #tarteaucitronPrivacyUrl,
#tarteaucitron #tarteaucitronPrivacyUrlDialog,
#tarteaucitronRoot .tarteaucitronDeny,
#tarteaucitronRoot .tarteaucitronAllow {
  background: #008300;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px !important;
  line-height: 1.2;
  padding: 5px 10px;
  text-decoration: none;
  margin-left: 7px;
}

#tarteaucitronRoot .tarteaucitronDeny {
  background: #9C1A1A;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert, #tarteaucitron #tarteaucitronPrivacyUrl, #tarteaucitron #tarteaucitronPrivacyUrlDialog {
  background: #fff;
  color: #333;
  font-size: 13px;
  margin-bottom: 3px;
  margin-left: 7px;
  padding: 5px 10px;
}

#tarteaucitronPercentage {
  background: #0A0 !important;
  box-shadow: 0 0 2px #fff, 0 1px 2px #555;
  height: 5px;
  left: 0;
  position: fixed;
  width: 0;
  z-index: 2147483644;
}

/***
 * Icon
 */
.tarteaucitronIconBottomRight {
  bottom: 0;
  right: 0;
}

.tarteaucitronIconBottomLeft {
  bottom: 0;
  left: 0;
}

.tarteaucitronIconTopRight {
  top: 0;
  right: 0;
}

.tarteaucitronIconTopLeft {
  top: 0;
  left: 0;
}

.tarteaucitronIconTopLeft #tarteaucitronManager {
  border-radius: 2px 7px 7px 2px;
}

.tarteaucitronIconTopRight #tarteaucitronManager {
  border-radius: 7px 2px 2px 7px;
}

.tarteaucitronIconBottomLeft #tarteaucitronManager {
  border-radius: 7px 7px 2px 2px;
}

.tarteaucitronIconBottomRight #tarteaucitronManager {
  border-radius: 7px 7px 2px 2px;
}

#tarteaucitronIcon {
  background: transparent;
  position: fixed;
  display: none;
  width: auto;
  z-index: 2147483646;
}

#tarteaucitronIcon #tarteaucitronManager {
  color: transparent;
  cursor: pointer;
  display: inline-block;
  font-size: 11px !important;
  padding: 8px 10px 8px;
  border: none;
}

#tarteaucitronIcon #tarteaucitronManager img {
  width: 50px;
  height: 50px;
}

#tarteaucitronRoot .tarteaucitronCross::before {
  content: "✗";
  display: inline-block;
  color: white;
}

#tarteaucitronRoot .tarteaucitronCheck::before {
  content: "✓";
  display: inline-block;
  color: white;
}

#tarteaucitronRoot .tarteaucitronPlus::before {
  content: "✛";
  display: inline-block;
  color: white;
}

/***
 * Small alert
 */
.tarteaucitronAlertSmallTop, .tarteaucitronAlertSmallBottom {
  bottom: 0;
}

#tarteaucitronAlertSmall {
  background: #333;
  display: none;
  padding: 0;
  position: fixed;
  right: 0;
  text-align: center;
  width: auto;
  z-index: 2147483646;
}

#tarteaucitronAlertSmall #tarteaucitronManager {
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 11px !important;
  padding: 8px 10px 8px;
}

#tarteaucitronAlertSmall #tarteaucitronManager:hover {
  background: rgba(255, 255, 255, 0.05);
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot {
  background-color: gray;
  border-radius: 5px;
  display: block;
  height: 8px;
  margin-bottom: 1px;
  margin-top: 5px;
  overflow: hidden;
  width: 100%;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen,
#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotYellow,
#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed {
  display: block;
  float: left;
  height: 100%;
  width: 0%;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen {
  background-color: #1B870B;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotYellow {
  background-color: #FBDA26;
}

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed {
  background-color: #9C1A1A;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesNumber {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 30px;
  padding: 0px 10px;
  vertical-align: top;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesNumber:hover {
  background: rgba(255, 255, 255, 0.3);
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer {
  display: none;
  max-height: 70%;
  max-width: 500px;
  position: fixed;
  right: 0;
  width: 100%;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
  background: #fff;
  border: 2px solid #333;
  color: #333;
  font-size: 11px;
  height: auto;
  overflow: auto;
  text-align: left;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList strong {
  color: #333;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesTitle {
  background: #333;
  margin-top: 21px;
  padding: 13px 0 9px 13px;
  text-align: left;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesTitle strong {
  color: #fff;
  font-size: 16px;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain {
  background: rgba(51, 51, 51, 0.1);
  padding: 7px 5px 10px;
  word-wrap: break-word;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain:hover {
  background: rgba(51, 51, 51, 0.2);
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain a {
  color: #333;
  text-decoration: none;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListLeft {
  display: inline-block;
  width: 50%;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListLeft a strong {
  color: darkred;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListRight {
  color: #333;
  display: inline-block;
  font-size: 11px;
  margin-left: 10%;
  vertical-align: top;
  width: 30%;
}

/***
 * Fallback activate link
 */
.tac_activate {
  background: #333;
  color: #fff;
  display: table;
  font-size: 12px;
  height: 100%;
  line-height: initial;
  margin: auto;
  text-align: center;
  width: 100%;
}

.tac_float {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.tac_activate .tac_float strong {
  color: #fff;
}

.tac_activate .tac_float .tarteaucitronAllow {
  background-color: #1B870B;
  display: inline-block;
}

/***
 * CSS for services
 */
ins.ferank-publicite, ins.adsbygoogle {
  text-decoration: none;
}

div.amazon_product {
  height: 240px;
  width: 120px;
}

.tarteaucitronIsAllowed .tarteaucitronDeny {
  opacity: 0.4 !important;
}

.tarteaucitronIsDenied .tarteaucitronAllow {
  opacity: 0.4 !important;
}

.tarteaucitronIsAllowed .tarteaucitronAllow {
  opacity: 1 !important;
}

.tarteaucitronIsDenied .tarteaucitronDeny {
  opacity: 1 !important;
}

.tarteaucitronLine .tarteaucitronAllow, .tarteaucitronLine .tarteaucitronDeny {
  opacity: 0.4;
}

#tarteaucitronServices_mandatory button.tarteaucitronAllow {
  opacity: 1;
}

div#tarteaucitronInfo {
  display: block !important;
  position: relative !important;
  text-align: center !important;
  max-width: 80% !important;
  padding: 15px 0 !important;
  margin: -10px auto 40px !important;
  font-size: 1em !important;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: #555;
}

a.tarteaucitronSelfLink {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  text-align: center !important;
  display: block;
  height: 30px;
}

.tarteaucitronMainLine .tarteaucitronH2 {
  font-size: 1.2em !important;
  margin-top: 4px !important;
}

span.tarteaucitronTitle.tarteaucitronH3 {
  margin-top: 12px !important;
}

#tarteaucitronCloseCross {
  position: absolute;
  color: white;
  font-size: 1.8rem;
  cursor: pointer;
  top: 10px;
  right: 26px;
}

.tarteaucitron-spacer-20 {
  height: 20px;
  display: block;
}

.tarteaucitron-display-block {
  display: block;
}

.tarteaucitron-display-none {
  display: none;
}

#tarteaucitronRoot #tarteaucitronAlertBig {
  background: #333;
}
#tarteaucitronRoot .tarteaucitronAllow {
  background: #008300 !important;
}
#tarteaucitronRoot .tarteaucitronDeny {
  background: #9c1a1a !important;
}
#tarteaucitronRoot #tarteaucitronCloseAlert {
  background: #fff;
}
#tarteaucitronRoot #tarteaucitronManager {
  background-color: #333;
}
#tarteaucitronRoot #tarteaucitronManager img {
  width: 36px;
  height: 36px;
}
#tarteaucitronRoot #tarteaucitronManager:hover {
  background-color: #404040;
}