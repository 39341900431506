
// -----------------------------------
// ||         Ils_parlent_de_nous         ||
// -----------------------------------

.ils_parlent_de_nous {
	.paragraphe_gros {
		font-size: 1.2rem;
	}

	#section1 {

	}

	#section2 {
		.col_gauche {
			.bloc_gris {
				padding: 4vw;
				background-color: $color_grisclair;

				.titre {
					font-weight: 700;
				}
			}

			.signature {
				font-size: 0.85rem;
				text-align: right;
				margin-bottom: 30px;
			}
		}

		.col_droite {
			padding: 40px;

			.filets {
				flex-basis: 30px;
				flex-grow: 0;
				padding-left: 10px;
				transform: translateX(-25px);
				.mot {
				    top: 100px;
				    left: 30px;
				    transform-origin: 0% 50%;
				    transform: rotate(-90deg);
				}

				.ep1 {
					position: absolute;
					left: 6px;
					top: 30px;
					height: 300px;
				}
				.ep2 {
					top: -20%;
					height: 600px;
				}
			}

			img { margin-top: 20px; }

			.col_droite {
				padding-left: 30px;
			}

			.dans_la_presse {
				margin-top: 30px;
				a {
					display: block;
					text-decoration: none;
					margin-top: 6px;
					font-size: 0.9rem;
				}
			}
		}
	}

	#section3 {

	}

	#section4 {

	}

	#section5 {

	}
}


@include media-breakpoint-down($responsive_breakpoint) {
	.ils_parlent_de_nous {
		#section1 {
			.absolute_centered {
				width: 90%;
			}
		}

		#section2 {
			.col_droite {
				padding: 20px;
				padding-right: 0px;
			}
		}
	}
}