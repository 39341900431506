

html { 
	overflow-x: hidden;
	overflow-y: scroll;
	margin: 0; padding: 0;
	width: 100%;
}

body {
	background-color: $main_bg_color;
	margin: 0; padding: 0;
	-webkit-text-size-adjust: 100%;
	overflow-x: hidden;
	width: 100%;
	padding-right: 0 !important; // Pour contrer les 17px des modales boostrap
}

.modal {
	padding-right: 0 !important; // Pour contrer les 17px des modales boostrap
}

section {
	position: relative;
	width: 100%;
}

section a {
	color: $links_color;
	font-weight: $links_weight;
	text-decoration: $links_decoration;
}
section a:hover {
	color: $links_color;
	font-weight: $links_weight;
	text-decoration: $links_decoration;
}

img { width: 100%; }

.container.row {
	margin: auto;
}

.vertical_center {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.padding_side_page {
	padding: 0 5vw;
}

.hidden {
	display: none;
}

.popover-header {
    display: flex !important;

    .titre {
    	flex: 1;
    }
}

.popover .close {
	cursor: pointer;
    float: right;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.admin_link {
	position: absolute;
	z-index: 10000;
	background-color: transparent;
	border-top: 26px solid #343a40;
	border-left: 26px solid #343a40;
	border-right: 26px solid transparent;
	border-bottom: 26px solid transparent;
	top: -36px;
	left: -36px;
	animation-name: admin_link_start;
    animation-duration: 0.8s;
	transition: 0.3s;

	i {
		color: white;
		position: absolute;
		top: -20px;
		left: -20px;
	}
}
.admin_link:hover {
	top: 0px;
	left: 0px;
}
@keyframes admin_link_start {
    from {top: 0px;left: 0px;}
    to {top: -36px;left: -36px;}
}

// ----------------- CSS SPECIFIQUE AU SITE -----------------

.full_site .site_content, .full_site footer, .bouton_reservation {
	transition: transform 0.5s;
}
.full_site.navbar_opened .site_content, .full_site.navbar_opened footer, .full_site.navbar_opened .bouton_reservation {
	transform: translateX(-$navbar_collapse_width);
}

.choix_langue {
	position: fixed;
	z-index: 100;
	display: block !important;
	top: 60px;
	right: $navbar_toggler_distance;
	.choose {
		font-size: 0.7rem;
	}
	p { margin: 0; }
	a { color: $color_black; }
	a:hover { text-decoration: underline; }
	a.active {
		text-decoration: underline;
		font-weight: 700;
	}
}

.filets {
	position: relative;
	color: $color_black;

	.filet_vertical {
		position: relative;
		display: inline-block;
		height: 100%;
		background-color: $color_black;

		.mot {
			z-index: 10;
			font-family: "questa-grande";
			position: absolute;
			font-size: 1.6rem;
			white-space: nowrap;
		}
	}
	.filet_vertical.ep1 { width: 1px; }
	.filet_vertical.ep2 { width: 4px; }
}

.shadow {
	box-shadow: 10px 10px 30px rgba($color_black, 0.3);
}

.paragraphe_gros {
	font-size: 1.5rem;
	font-family: "questa-grande";
}

p {
	color: $color_black;
	font-size: 0.95rem;
	font-weight: 300;
}

.absolute_centered {
	@extend %absolutecenter_xy;
}

.entete_page {
	height: 50vh;
	text-align: center;
	h1 {
		color: white;
		font-size: 4rem;

		span { font-family: "questa-grande"; }
		.part1 {
			font-style: italic;
		}
		.part2 {
			font-weight: 700;
		}
	}
	h3 { color: $color_white; }
}
.restaurant .entete_page { background: url(../images/restaurant_entete.jpg) no-repeat center center; background-size: cover; }
.reservation .entete_page { background: url(../images/reservation_entete.jpg) no-repeat center center; background-size: cover; }
.chef_equipe .entete_page { background: url(../images/chef_equipe_entete.jpg) no-repeat center center; background-size: cover; }
.carte_menu .entete_page { background: url(../images/carte_menu_entete.jpg) no-repeat center center; background-size: cover; }
.ils_parlent_de_nous .entete_page { background: url(../images/ils_parlent_de_nous_entete.jpg) no-repeat center center; background-size: cover; }
.village_yvoire .entete_page { background: url(../images/village_entete.jpg) no-repeat center center; background-size: cover; }
.actualites .entete_page { background: url(../images/actualites_entete.jpg) no-repeat center center; background-size: cover; }
.contact_acces .entete_page { background: url(../images/contact_acces_entete_2023.jpg) no-repeat center center; background-size: cover; }

.titre_type_1 {
	text-align: right;
	color: $color_black;
	.part1 {
	    font-size: 1.3rem;
	    font-weight: 500;
	}
	.part2 {
	    font-family: "questa-grande";
	    font-size: 3rem;
	    line-height: 2rem;
	    font-weight: 700;
	}
}




// BOUTONS

.bouton {

}

.bouton_type_1 {
	color: $color_black;
	text-transform: uppercase;
	text-decoration: none;
	padding-bottom: 4px;
	border-bottom: 2px solid $color_black;
}
.bouton_type_1:hover {
	color: $color_black;
	text-decoration: none;
}

// ----------------------------------------------------------


@include media-breakpoint-down($responsive_breakpoint) {
	.filets { display: none; }
}