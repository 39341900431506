
$webapp_grisfonce: #383938;
$webapp_grisclair: #898c8e;

.app_container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 5vw;
    color: $webapp_grisfonce;

    .hidden {
        display: none !important;
    }

    h2 {
        font-size: 1.3rem;
        text-align: center;
    }

    .lang_en {
        color: $webapp_grisfonce;
    }
    .lang_fr {
        color: $webapp_grisclair;
    }

    .filet {
        width: 50px;
        height: 4px;
        background-color: $webapp_grisfonce;
        margin-top: 12px;
           margin-bottom: 30px;
    }

    .button {
        display: inline-block;
        cursor: pointer;

        img {
            width: 55px;
        }
    }

    .homescreen {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        p {
            text-align: center;
            font-weight: 700;
            margin: 0;
        }

        .back_container, .navigate_container {
            margin-bottom: 20px;
        }
    }

    .main_menu {
        text-align: center;

        .entete {
            p {
                font-weight: 500;
                font-size: 1.2rem;
                margin: 0;
            }
        }

        .filet {
            margin-top: 20px;
            margin-left: auto;
            margin-right: auto;
        }

        .nav_menu {
            list-style: none;
            padding: 0;

            a {
                color: $webapp_grisfonce;
                font-size: calc(1.2rem + 2vw);
            }
        }
    }

    .nav_container {
        align-self: flex-start;
        justify-self: flex-start;
        padding: 10px;
        margin-bottom: 10px;
    }

    .lvl1_contents {
        min-height: calc(100vh - 10vw);
        min-width: 90vw;

        .js-menu-content {
            text-align: center;
        }
        .content_carte {
            text-align: left;
        }

        .titre {
            font-size: 1.4rem;
            font-weight: 700;
            margin-bottom: 20px;

            span {
                margin-right: 6px;
            }
        }

        .entry {
            margin-bottom: 26px;

            .nom {
                margin-bottom: 6px;

                .detail {
                    font-style: italic;
                    font-size: 0.85rem;
                }
            }

            .prix {
                text-align: right;
                font-size: 1.2rem;
                font-weight: 700;
            }
        }

        h3 {
            font-size: 1.5rem;
            font-weight: 700;
            margin-bottom: 20px;
        }

        .bloc {
            p, span {
                font-family: "adelle-sans",sans-serif !important;
                font-size: 1rem !important;
                font-weight: 400 !important;
            }
        }

        .filet {
            margin: 30px auto;
        }
        .filet:last-child { display: none;}
    }

    .version_pdf {
        display: block;
        width: 75%;
        max-width: 400px;
        margin: auto;
    }
}