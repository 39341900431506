
// ------------------------------------
// ||           Carte Menu           ||
// ------------------------------------

.carte_menu {
	#section1 {
		
	}

	#section2 {

		.col_gauche {
			.nav_menu {
				padding: 40px;
				text-align: center;
				list-style: none;
				font-size: 2rem;
				a {
					font-weight: 300;
					text-decoration: none;
				}
				a:hover {
					text-decoration: underline;
					color: $color_black;
				}
				a.active {
					font-weight: 700;
					text-decoration: underline;
				}
			}

			.bloc_gris {
				padding: 4vw;
				background-color: $color_grisclair;

				.titre {
					font-weight: 700;
				}
			}
		}

		.col_droite {
			padding: 40px;
			padding-top: 0;

			.version_pdf {
				position: absolute;
				top: 20px;
				right: 20px;

				img {
					display: inline-block;
					width: 60px;
				}
			}

			.logo_bib_gourmand {
				position: absolute;
				width: 80px;
				top: 10px;
				right: 11vw;
			}

			.menu {
				padding: 3vw;

				/* .titre_menu {
					position: relative;
					font-weight: 700;
					margin-bottom: 15px;
					margin-top: 40px;

					span {
						margin: 0 10px;
					}

					div {
						display: inline-block;
						transform: translateY(-8px);
					}
				} */

				td.titre {
					position: relative;
					font-size: 1rem !important;
					font-weight: 700;
					margin-bottom: 15px;
					padding-top: 40px !important;

					span {
						margin: 0 10px;
					}

					div {
						display: inline-block;
						transform: translateY(-8px);
					}
				}

				.menu_table {
					.detail {
						display: block;
						font-size: 0.75rem;
						font-weight: 300;
					}
					td {
						padding: 10px;
						padding-left: 0;
						font-size: 0.9rem;
					}
					.nom {

					}
					.prix {
						width: 100px;
						text-align: right;
						vertical-align: top;
						font-weight: 700;
					}
				}

				h3 {
					margin-bottom: 40px;
					font-weight: 700;

					.soustitre {
						font-size: 1rem;
						font-weight: 300;
					}
				}

				.bloc {
					text-align: center;
					margin-top: 25px;
					margin-bottom: 25px;

					p {
						margin: 0;
					}

					.ou {
						font-weight: 700;
					}

					.detail {
						display: block;
						font-size: 0.75rem;
						font-weight: 300;
					}
				}
				.filet {
					display: block;
					width: 200px;
					height: 1px;
					margin: auto;
					background-color: $color_black;
				}
			}

			.filets {
				flex-basis: 30px;
				flex-grow: 0;
				padding-left: 10px;
				.mot {
				    top: 50px;
				    left: 30px;
				    transform-origin: 0% 50%;
				    transform: rotate(-90deg);
				}

				.ep1 {
					position: absolute;
					left: 6px;
					top: 30px;
					height: 500px;
				}
				.ep2 {
					top: -3%;
					height: 800px;
				}
			}
		}
	}

	#section3 {
		.texte {
			position: absolute;
			z-index: 1;
			bottom: 50px;
			left: 50%;
			transform: translateX(-50%);

			p {
				color: $color_white;
				margin-bottom: 6px;
			}

			.filet {
				width: 50%;
				height: 4px;
				background-color: $color_white;
				margin: auto;
			}
		}

		img {
			width: 100%;
			height: 40vw;
			object-fit: cover;
		}
	}

	#section4 {
		padding: 40px 0;
		p {
			padding-right: 10vw;
			padding-left: 100px;
		}

		.titre_type_1 {
			display: inline-block;
			margin-bottom: 20px;
		}

		.filets {
			padding-left: 10px;
			.mot {
			    bottom: 100px;
			    right: 30px;
			    transform-origin: 100% 50%;
			    transform: rotate(-90deg);
			}
			.ep2 {
				top: -20%;
				height: 110%;
			}
		}
	}

	#section5 {
		margin: 60px 0;

		.cadre {
			position: absolute;
			height: 10vw;
			width: 10vw;
			left: 30%;
			border: 1px solid $color_black;
			transform: translateY(-30px);
		}

		.col_droite {
			transform: translate(-50px, -50px);
			img {
				float: left;
				width: 45%;
				margin-left: 20px;
			}

			.img2 {
				position: absolute;
				top: -20px;
				left: 0;
				transform: translateY(-100%);
			}

			.img5 {
				position: absolute;
				top: 20px;
				right: 0;
				transform: translateY(100%);
			}
		}
	}

	.content_carte_vins .version_pdf {
		top: 100px !important;
		left: 50%;
		img { width: 150px !important; }
	}

	@include media-breakpoint-down($responsive_breakpoint) {
		.content_carte_vins {
			.version_pdf {
				display: inline-block;
				position: relative !important;
				top: 0 !important;
				right: initial !important;
				transform: translateX(-50%);

				img {
					width: 150px;
				}
			}
		}
	}
}

@include media-breakpoint-down($responsive_breakpoint) {
	.carte_menu {
		#section2 {
			.col_gauche {
				display: flex;
				flex-direction: column-reverse;
			}

			.col_droite {
				margin-top: 20px;
				padding-left: 20px;
				padding-right: 20px;

				.version_pdf {
					top: -60px;
					right: 10px;
				}

				.logo_bib_gourmand {
					right: -10px;
				}
			}

			.content_menu_terroir {
				h3 {
					padding-right: 60px;
				}
			}
		}

		#section4 {
			p {
				padding: 0;
			}
		}

		#section5 {
			.cadre {
				display: none;
			}
			.img2, .img3, .img4, .img5 {
				position: relative !important;
				margin: 0 !important;
				float: left !important;
				transform: none !important;
				width: 50% !important;
				top: 0 !important;
			}

			.col_droite {
				transform: none;
				width: 100% !important;
			}
		}
	}
}
