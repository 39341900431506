
// -----------------------------------
// ||         Village_yvoire         ||
// -----------------------------------

.village_yvoire {
	#section1 {
		h1, h3 { color: $color_black; }
	}

	#section2 {
		.col_gauche {
			.paragraphe_gros {
				padding: 3vw;
				padding-right: 6vw;
				margin: 0;
			}

			.bloc_gris {
				padding: 4vw;
				background-color: $color_grisclair;

				.titre {
					font-weight: 700;
				}

				p {
					padding-right: 6vw;
				}
			}

			img {
				width: 75%;
				margin-top: 40px;
			}
		}

		.col_droite {
			padding: 4vw;

			.filets {
				position: absolute;
				left: 0px;
				flex-basis: 30px;
				flex-grow: 0;
				padding-left: 10px;
				transform: translateX(-25px);
				.mot {
				    top: 100px;
				    left: 30px;
				    transform-origin: 0% 50%;
				    transform: rotate(-90deg);
				}

				.ep1 {
					position: absolute;
					left: 6px;
					top: 30px;
					height: 300px;
				}
				.ep2 {
					top: -100px;
					height: 600px;
				}
			}

			img { margin-top: 10px; }

			.col_droite {
				padding-left: 30px;
			}

			.texte {
				padding-top: 40px;
				padding-left: 40px;
			}
		}
	}

	#section3 {
		margin-top: 100px;

		.img1, .img2 {
			position: absolute;
			height: 15vw;
			width: 15vw;
			top: -50px;
		}

		.img1 {
			left: 50px;
		}

		.img2 {
			left: calc(70px + 15vw);
		}

		.cadre {
			position: absolute;
			width: 25%;
			height: 80%;
			right: 10%;
			top: -110px;
			padding: 30px;
			padding-top: 10px;
			border: 2px solid $color_black;

			h2, .part2 {
				line-height: 1.5rem !important;
			}
			.soustitre {
				font-size: 1rem;
			}
		}
	}

	#section4 {
		height: 300px;

		.zone_photo {
			position: absolute;
			right: 30%;
			top: -80px;
			width: 15%;
			text-align: center;
			font-style: italic;
			p { margin-top: 10px; }
		}

		.filet {
			position: absolute;
			right: 20%;
			top: -350px;
			width: 3px;
			height: 500px;
			background-color: $color_black;
		}
	}

	#section5 {

	}
}


@include media-breakpoint-down($responsive_breakpoint) {
	.village_yvoire {
		#section1 {
			.absolute_centered {
				width: 90%;
			}
		}

		#section2 {
			.col_gauche {
				img {
					width: 100%;
				}
			}

			.col_droite {
				/* padding: 20px;
				padding-right: 0;
				padding-left: 30px; */
				.row {
					img { display: none; }
				}

				.texte {
					padding-left: 0;
				}
			}
		}

		#section3 {
			margin-top: 150px;
			.cadre {
				width: 90%;
				left: 5%;
				right: auto;
			}

			.img1, .img2 {
				position: relative;
				display: inline-block;
				width: 49%;
				height: 30vh;
				left: 1vw;
				top: auto;
				object-fit: cover;
			}
		}

		#section4 {
			height: auto;
			.filet { display: none; }
			.zone_photo {
				position: relative;
				top: 20px;
				left: 15%;
				width: 60%;
				margin-bottom: 100px;
				/* top: 20px;
				right: 50%;
				transform: translateX(50%);
				width: 60%; */
			}
		}
	}
}