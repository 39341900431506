
// -----------------------------------
// ||         Actualites         ||
// -----------------------------------

.actualites {

	#section1 {
		.col1 {
			padding: 5vw;
			text-align: center;

			p {
				margin: 5px;
				font-size: 1.3rem;
			}

			h1 {
				color: $color_black;
				opacity: 1;
				font-size: 3.5rem;
				span { font-family: "questa-grande"; }
				.part1 {
					font-style: italic;
				}
				.part2 {
					font-weight: 700;
				}
			}

			h5 {
				font-weight: 700;
				margin-top: 30px;
			}

			.yvoire {
				margin-top: 20px;
				font-size: 0.8rem;
				font-weight: 700;
			}

			.filet {
				margin: auto;
				height: 2px;
				width: 46px;
				background-color: $color_black;
			}
		}

		.col2 {
			flex-basis: 30px;
			flex-grow: 0;
			padding: 30px 0 !important;

			.filet_vertical._1 {
				top: 220px;
				height: 70%;
			}

			.filet_vertical._2 {
				top: 200px;

				.mot {
					z-index: 100;
					top: 0px;
					left: 30px;
					transform-origin: 0 50%;
					transform: rotate(-90deg) translateX(-100%);
				}
			}
		}

		.col3 {
			.reseaux {
				position: absolute;
				bottom: 30px;
				right: 50px;
				p { margin: 0; }
				a { font-size: 1.4rem; }
			}
		}
	}

	#section2 {
		margin-bottom: 100px;
		
		.col_gauche {
			.bloc_gris {
				padding: 4vw;
				background-color: $color_grisclair;

				.titre {
					font-weight: 700;
				}
			}

			.signature {
				font-size: 0.85rem;
				text-align: right;
				margin-bottom: 30px;
			}
		}

		.col_droite {
			padding: 6vw;

			.news_container {
				margin-bottom: 100px;

				h1 {
					font-family: "questa-grande";
					font-style: italic;
				}

				h2 {
					margin-top: 26px;
					margin-bottom: 20px;
					font-size: 2.6rem;
				}

				h3 {
					font-family: "questa-grande";
					font-size: 2rem;
					margin: 14px 0;
				}

				h4 {
					font-size: 1.4rem;
					margin: 10px 0;
				}



				.row {
					margin: 1.5rem 0;

					> div:first-child { padding-left: 0; }
					> div:last-child { padding-right: 0; }

					.col_texte {
						h3:first-child { margin: 0; }
						h4:first-child { margin: 0; }
						p:last-child { margin: 0; }
					}

					.col_image {
						> div {
							position: relative;
							height: 100%;
						}

						img {
							position: absolute;
							@extend %imgcover;
						}

						img.contain {
							object-fit: contain;
						}
					}
				}

				@include media-breakpoint-down($responsive_breakpoint) {
					.row {
						.col_texte, .col_image {
							padding: 0;
						}

						.col_texte {
							order: 2;
						}

						.col_image {
							order: 1;
							min-height: 300px;
							margin-bottom: 30px;
						}
					}
				}
			}

			.date {
				text-align: right;
			}

			.titre {
				font-size: 2.2rem;
				font-weight: 700;
				margin-bottom: 30px;
			}

			.texte {

			}

			.share {
				text-align: right;
				margin-top: 10px;
				p {
					font-size: 1rem;
					color: darken($color_grisclair, 20%);
					margin-right: 10px;
					transform: translateY(-4px);
				}
				* {
					display: inline-block;
				}
			}

			.bloc {
				text-align: center;
				margin-top: 25px;
				margin-bottom: 25px;

				p {
					margin: 0;
				}

				.ou {
					font-weight: 700;
				}

				.detail {
					display: block;
					font-size: 0.75rem;
					font-weight: 300;
				}
			}
			.filet {
				display: block;
				width: 200px;
				height: 1px;
				margin: auto;
				background-color: $color_black;
			}
		}
	}
}


@include media-breakpoint-down($responsive_breakpoint) {
	.actualites {
		#section1 {
			.absolute_centered {
				width: 90%;
			}
		}
	}
}